import { styled } from "styled-components";
import { BREAKPOINTS } from "../../Constants";

const CustomFooterWrapper = styled.footer`
  &.custom-footer-component {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 1rem;
    }

    h1 {
      @media (max-width: 720px) {
        text-align: center;
      }
    }

    .content {
      padding-top: 4rem;
      padding-bottom: 4rem;
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 4rem;
      row-gap: 3rem;

      @media (min-width: ${BREAKPOINTS.md}px) {
        grid-template-columns: 1fr 1fr;
      }

      .left {
        display: block;
        max-width: 500px;
      }

      .right .info {
        display: flex;
        justify-content: flex-end;
        gap: 4rem;
        line-height: 1.5rem;

        @media (max-width: 720px) {
          padding: 0.5rem;
        }

        ul.social-media {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            margin-bottom: 0.5rem;

            a {
              text-decoration: underline !important;
              text-decoration-color: transparent;
              color: inherit;
              margin-bottom: 1rem;
              transition: 0.3s;

              &:hover {
                text-decoration-color: inherit;
                transition: 0.3s;
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomFooterWrapper;

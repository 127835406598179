import { useCallback, useEffect, useState } from "react";
import ContainersWrapper from "./style";
import { useNavigate } from "react-router-dom";
import { containerService } from "../../Services";
import { IContainer } from "../../Types";
import { Box, Tooltip } from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";
import { AdminContainerCard } from "../../Components";
import { useValidateToken } from "../../Hooks";

const Containers: React.FC = () => {
  const [containers, setContainers] = useState<IContainer[]>([]);
  const navigate = useNavigate();

  useValidateToken();

  const [page, setPage] = useState(1);

  const requestUsers = useCallback(() => {
    containerService.getAll(page).then((paginationContainer) => {
      const {
        data: users
      } = paginationContainer

      setContainers(users);
    });
  }, [page])

  useEffect(requestUsers, [page, requestUsers])

  const handleDelete = useCallback(() => requestUsers(), [requestUsers])

  const handleRedirectNewContainer = useCallback(() => {
    navigate("new");
  }, [navigate]);
  
  return (
    <ContainersWrapper className="container">
      <h1 className="page-title">Contêineres</h1>

      <div className="user-container">
        {containers?.map((container: IContainer, index: number) => (
          <AdminContainerCard key={`container-card-${index}`} container={container} onDelete={handleDelete}/>
        ))}
        <Tooltip title="Adicionar novo contêiner">
          <Box className="cta-create-new clickable" onClick={handleRedirectNewContainer}>
            <BiPlusCircle size={24}/>
          </Box>
        </Tooltip>
      </div>
    </ContainersWrapper>
  );
}

export default Containers;
import React from 'react';
import ContainerCollectionWrapper from './container-collection.style';
import { IContainer } from '../../Types';
import { ContainerCard } from '../ContainerCard';

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/scss/alice-carousel.scss";

interface IProps {
  containers: IContainer[];
  carouselPadding: number;
}

const ContainerCollectionViewNoMemo: React.FC<IProps> = (props) => (
  <ContainerCollectionWrapper className='container-collection-component'>
    <AliceCarousel
      mouseTracking 
      animationType="fadeout"
      disableDotsControls
      disableButtonsControls
      paddingLeft={props.carouselPadding}
      paddingRight={props.carouselPadding}
      responsive=
      {{
        1:    { items: 1 },
        768:  { items: 2 },
        992:  { items: 3 },
        1400: { items: 4 },
      }}
      items={props.containers?.map((container, index) => (
        <ContainerCard key={index} container={container}/>
      ))}
    />
  </ContainerCollectionWrapper>
);

const ContainerCollectionView = React.memo(ContainerCollectionViewNoMemo);
export default ContainerCollectionView;

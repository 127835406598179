interface IProps {
  name: string;
}

const formatCurrencyName = (props: IProps) => {
  const { name } = props;

  const formattedString = name.replace(/^USD/, '');

  return formattedString;
};

export default formatCurrencyName;
import React from 'react';
import ProductWrapper from './product.style';
import { BiTrash } from 'react-icons/bi';

interface IProps {
  name: string;
  weight: number;
  volume: number;
  onDelete: () => void;
}

const ProductViewNoMemo: React.FC<IProps> = (props) => (
  <ProductWrapper className='product-component'>
    <span className="product-name">{props.name}</span>
    <span>{props.weight} kg</span>
    <div className="divider vertical bg-accent"></div>
    <span>{props.volume} m³</span>
    <button className="btn-delete bg-error txt-white" onClick={props.onDelete}>
      <BiTrash />
    </button>
  </ProductWrapper>
);

const ProductView = React.memo(ProductViewNoMemo);
export default ProductView;

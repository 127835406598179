import { IGuest } from "../../../Types";
import { IHeadCell } from "../Types";

const guestHeadCells: IHeadCell<IGuest>[] = [
  {
    id: 'name',
    numeric: false,
    phone: false,
    disablePadding: false,
    label: 'Nome',
  },
  {
    id: 'email',
    numeric: false,
    phone: false,
    disablePadding: false,
    label: 'E-mail',
  },
  {
    id: 'phone',
    numeric: false,
    phone: true,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'companyName',
    numeric: false,
    phone: false,
    disablePadding: false,
    label: 'Empresa',
  },
  {
    id: 'lineOfBusiness',
    numeric: false,
    phone: false,
    disablePadding: false,
    label: 'Ramo',
  },
  {
    id: 'locality',
    numeric: false,
    phone: false,
    disablePadding: false,
    label: 'Local',
  },
  {
    id: 'message',
    numeric: false,
    phone: false,
    disablePadding: false,
    label: 'Mensagem',
  },
]

export {
  guestHeadCells,
}
import React, { useCallback, useState } from "react";
import CalculatorWrapper from "./style";
import { CustomFooter, DrawerOptions, Header, Calculator as CalculatorComponent } from "../../Components";
import { Drawer } from "@mui/material";

const Calculator: React.FC = () => {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const handleOnClickShowDrawer = useCallback(() => setShowDrawer(true), []);
  const handleOnClickCloseDrawer = useCallback(() => setShowDrawer(false), []);

  return (
    <CalculatorWrapper>
      <Header onClickShowDrawer={handleOnClickShowDrawer} />
      <Drawer onClose={handleOnClickCloseDrawer} open={showDrawer}>
        <DrawerOptions />
      </Drawer>

      <CalculatorComponent />
      <CustomFooter />
    </CalculatorWrapper>
  )
}

export default Calculator
//import axios from "axios";

import { IContainer } from "../Types";
import Service from "./Service";
import IPagination from "./Types/IPagination";

const containerService = {
  getAll: async (page: number): Promise<IPagination<IContainer>> => {
    const pagination = (await Service.get(
      `Container/all?PageNumber=${page}&PageSize=10`
    )) as IPagination<IContainer>;
    return pagination;
  },

  getAllLandingPage: async (page: number): Promise<IPagination<IContainer>> => {
    const pagination = (await Service.get(
      `Container?PageNumber=${page}&PageSize=10`
    )) as IPagination<IContainer>;
    return pagination;
  },

  get: async (id: string): Promise<IContainer | undefined> => {
    let container = undefined;
    let pagination = undefined;
    let nextPage = Service.baseURL + `Container?PageNumber=1&PageSize=10`;

    while (container === undefined) {
      pagination = (await Service.get(nextPage, {
        fullUrl: true,
      })) as IPagination<IContainer>;
      container = pagination.data.find((container) => container.id === id);
      nextPage = pagination.nextPage;
      if (!nextPage) break;
    }

    return Promise.resolve(container);
  },

  post: async (body: Partial<IContainer>): Promise<IContainer | undefined> => {
    return (await Service.post(`Container`, body)) as IContainer;
  },

  put: async (
    id: string,
    body: Partial<IContainer>
  ): Promise<IContainer | undefined> => {
    return (await Service.put(`Container/${id}`, body)) as IContainer;
  },

  delete: async (id: string): Promise<void> => {
    return await Service.delete(`Container/${id}`);
  },
};

export default containerService;

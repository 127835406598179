import React from 'react';
import { DashboardHeaderWrapper, DesktopHeader, MobileHeader } from './dashboard-header.style';

import whiteLogo from "../../Assets/logo-white.png";
import { BiLogOut } from 'react-icons/bi';

interface IProps {
  renderDashboardNavbar: React.ReactNode;
  handleLogout: () => void;
}

const DashboardHeaderViewNoMemo: React.FC<IProps> = (props) => (
  <DashboardHeaderWrapper className='dashboard-header-component bg-primary'>
    <DesktopHeader>
      <div className="logo-container">
        <img src={whiteLogo} alt="Logotipo do mid4u" className="mid4u-logo" />
      </div>

      <div className="divider bg-dark-primary"></div>

      {props.renderDashboardNavbar}

      <div className="spacer"></div>

      <nav>
        <div className="clickable" onClick={props.handleLogout}>
          <BiLogOut size={20}/>
          Sair
        </div>
      </nav>
    </DesktopHeader>

    <MobileHeader>
      {props.renderDashboardNavbar}
    </MobileHeader>
  </DashboardHeaderWrapper>
);

const DashboardHeaderView = React.memo(DashboardHeaderViewNoMemo);
export default DashboardHeaderView;

import { ITaxKeys } from "../Types";

interface IProps {
  key: ITaxKeys;
};

const formatFullName = (props: IProps) => {
  const { key } = props;

  switch (key) {
    case 'USDBRL':
      return 'Dólar';
    case 'CNYBRL':
      return 'Yuan';
    case 'EURBRL':
      return 'Euro';
    case 'GBPBRL':
      return 'Libra';
    case 'ARSBRL':
      return 'Peso';
    default:
      return 'Real';
  };
}

export default formatFullName;

import React, { useMemo } from 'react';
import ContainerCardView from './container-card.view';
import IContainerCard from './container-card.props';
import { formatNumberToLocale } from '../../Library';

const ContainerCard: React.FC<IContainerCard> = (props) => {
  const { 
    id,
    imageUrl,
    name,
    innerSize,
    height,
    width,
    depth,
    weightCapacity,
    cubage,
  } = props.container;

  const formattedInnerSize = useMemo<string>(() => formatNumberToLocale({ number: innerSize ?? 0 }), [innerSize]);
  const formattedDepth = useMemo<string>(() => formatNumberToLocale({ number: depth ?? 0 }), [depth]);
  const formattedWidth = useMemo<string>(() => formatNumberToLocale({ number: width ?? 0 }), [width]);
  const formattedHeight = useMemo<string>(() => formatNumberToLocale({ number: height ?? 0 }), [height]);
  const formattedWeightCapacity = useMemo<string>(() => formatNumberToLocale({ number: weightCapacity ?? 0 }), [weightCapacity]);
  const formattedCubage = useMemo<string>(() => formatNumberToLocale({ number: cubage ?? 0 }), [cubage]);

  return <ContainerCardView 
    id={id}
    image={imageUrl}
    name={name}
    innerSize={formattedInnerSize}
    height={formattedHeight}
    width={formattedWidth}
    depth={formattedDepth}
    weightCapacity={formattedWeightCapacity}
    cubage={formattedCubage}
  />;
};

export default ContainerCard;

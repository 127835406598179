import React from "react";
import IncotermsDropdownWrapper from "./incoterms-dropdown.style";
import {
  ExtendedRefs,
  ReferenceType,
} from "@floating-ui/react";

interface IProps {
  className: string;
  incotermsOptions: string[];
  listInteractionRefs: ExtendedRefs<ReferenceType | null>;
  floatingStyles: React.CSSProperties;
  onChangeIncoterm: (acronym: string) => void;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement> | undefined) => Record<string, unknown>;
}

const IncotermsDropdownViewNoMemo: React.FC<IProps> = (props) => (
  <IncotermsDropdownWrapper
    className={`incoterms-dropdown-component ${props.className}`}
    ref={props.listInteractionRefs.setFloating}
    style={props.floatingStyles}
    {...props.getFloatingProps()}
  >
    {props.incotermsOptions?.map((incotermsOption, index) => (
      <div
        key={index}
        className="incoterms-dropdown__option"
        onClick={() => props.onChangeIncoterm(incotermsOption)}
      >
        {incotermsOption}
      </div>
    ))}
  </IncotermsDropdownWrapper>
);

const IncotermsDropdownView = React.memo(IncotermsDropdownViewNoMemo);
export default IncotermsDropdownView;

import { RiShipFill } from "react-icons/ri";
import { BiIdCard, BiUserCircle } from "react-icons/bi";

const routes = [
  {
    name: "Visitantes",
    path: "guests",
    icon: BiUserCircle,
  },
  {
    name: "Usuários",
    path: "users",
    icon: BiIdCard,
  },
  {
    path: "containers",
    name: "Contêineres",
    icon: RiShipFill,
  },
]

export default routes;
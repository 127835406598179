import styled, { css } from "styled-components";
import coverImage from "../../Assets/cover-image-2.jpg";

const BrandWrapper = styled.div`
  ${() => css`
    &.brand-component {
      .brand-image-component {
        width: 100%;
        height: 580px;

        background-image: url(${coverImage});
        background-repeat: no-repeat;
        background-size: cover;
        color: white;

        .brand-image-component-gradient {
          width: 100%;
          height: 580px;
          position: absolute;
          z-index: 100;
          background: linear-gradient(
            132deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 52.9%
          );
        }

        .brand-image-component-text-container {
          width: 100%;
          height: 100%;
          padding-top: 41px;

          @media (max-width: 720px) {
            padding-left: 1rem;
          }

          .brand-image-component__text {
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 500;
            gap: 1rem;
            font-family: "Roboto", "Lexend", "Sora";

            h1 {
              color: #fff;
              font-family: Roboto, Sora;
              font-size: 36px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            h1,
            span {
              margin-bottom: 0;
              filter: drop-shadow(0px 0px 10px #000000);
            }

            span {
              color: #fff;
              font-family: Roboto, Sora;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  `}
`;

export default BrandWrapper;

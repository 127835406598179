import React, { useCallback, useEffect, useState } from 'react';
import ContainerCollectionView from './container-collection.view';
import IContainerCollection from './container-collection.props';
import { useGetBreakpoint } from '../../Hooks';
import { IContainer } from '../../Types';
import { containerService } from '../../Services';
import { CONTAINER_WIDTH } from '../../Constants';

const ContainerCollection: React.FC<IContainerCollection> = () => {
  const [containers, setContainers] = useState<IContainer[]>([]);

  useEffect(() => {
    containerService.getAllLandingPage(1).then((paginationContainer) => {
      const {
        data: containers
      } = paginationContainer;
      setContainers(containers);
    });
  }, [])

  const getBreakpoint = useGetBreakpoint();

  const calculatePadding = useCallback(() => {
    const breakpoint = getBreakpoint();
    if (breakpoint === 'xsm') return 15;
    const padding = (window.innerWidth - CONTAINER_WIDTH[breakpoint]) / 2;
    return padding;
  }, [getBreakpoint])
  const [carouselPadding, setCarouselPadding] = useState<number>(calculatePadding());

  useEffect(() => {
    const handleWindowResize = () => {
      setCarouselPadding(calculatePadding());
    };

    window.addEventListener('resize', handleWindowResize);
    return () => { window.removeEventListener('resize', handleWindowResize) };
  }, [calculatePadding]);

  return (
    <ContainerCollectionView
      containers={containers}
      carouselPadding={carouselPadding}
    />
  );
};

export default ContainerCollection;

import React from 'react';
import BrandWrapper from './brand.style';

interface IProps { }

const BrandViewNoMemo: React.FC<IProps> = () => (
  <BrandWrapper className='brand-component'>
    <div className="brand-image-component">
      <div className="brand-image-component-gradient" />
      <div className="brand-image-component-text-container container">
        <div className="brand-image-component__text">
          <h1>
            Torne sua experiência com <br />o comércio exterior mais <br />eficiente e
            segura.
          </h1>
          <span>Somos o meio para você importar <br />e exportar com segurança!</span>
        </div>
      </div>
    </div>
  </BrandWrapper>
);

const BrandView = React.memo(BrandViewNoMemo);
export default BrandView;

interface IProps {
  number: number
};

const formatNumberToCurrencyString = (props: IProps): string => {
  const { number } = props;

  const brazilianFormat = number.toLocaleString('en-US', {
    currency: 'BRL',
  });

  return brazilianFormat;
}

export default formatNumberToCurrencyString;
import { styled, css } from "styled-components";

const MapWrapper = styled.div`
  ${() => css`
    path:focus-visible {
      outline: none;
    }

    &.map-component {
      display: flex;
      justify-content: center;
      flex-direction: column;

      position: relative;
      z-index: 1;
      height: 80vh;

      gap: 30px;

      .map-box {
        height: 100%;
        width: 100%;

        padding: 0 10rem;
      }

      @media (max-width: 720px) {
        padding: 1rem;
      }
    }

    .filter-container {
      display: flex;

      h1 {
        text-align: left;
      }
    }

    .select-state-field {
      padding: 12px 24px;
      background: transparent;
      border-radius: 5px;
      font-size: 1rem;
      border: 1px solid #ccc;

      option {
        background-color: transparent;
        color: #333;
      }
    }
  `}
`;

export default MapWrapper;

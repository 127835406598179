import { styled } from "styled-components";

const FileFieldWrapper = styled.div`
  &.input-field-component{
    box-shadow: 0 0 0 2px var(--gray-2);
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
    gap: .75rem;
    transition: .3s;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      max-width: 24px;

      > svg {
        height: 2.5rem;
        width: 24px;
      }
      
      > img {
        width: 20px;
      }
    }

    &.error {
      box-shadow: 0 0 0 2px var(--error);
      transition: .3s;

      svg, svg path {
        fill: var(--error);
        stroke: var(--error);
      }

      .label-field label {
        color: var(--error);
        transition: .3s;
      }
      

      &.active {
        box-shadow: 0 0 0 2px var(--error);
        transition: .3s;

        svg, svg path {
          fill: var(--error);
          transition: .3s;
        }
        label {
          color: var(--error);
          transition: .3s;
        }
      }
    }

    svg {
      transition: .3s;
      fill: var(--gray-1);
      stroke: var(--gray-1);
      height: 2.5rem;
      width: 24px;
      
      path {
        fill: var(--gray-1);
        stroke: var(--gray-1);
      }
    }

    .label-field {
      position: relative;
      height: 2.5rem;
      width: 100%;

      label {
        position: absolute;
        transition: .3s;
        inset: 0;
        color: var(--gray-1);
        display: flex;
        align-items: center;
      }
      
      .input-field-mui {
        transition: .3s;
        width: 100%;

        input {
          font-weight: bold;
        }

        > div {
          height: 100%;
        }
      }
    }

    &.active, &.filled {
      label {
        transform: translateY(-13px);
        font-size: .75rem;
        transition: .3s;
      }
      .input-field-mui {
        transform: translateY(13px);
        transition: .3s;
      }
    }

    &.active {
      box-shadow: 0 0 0 2px var(--primary);
      transition: .3s;

      svg, svg path {
        fill: var(--primary);
        transition: .3s;
      }
      label {
        color: var(--primary);
        transition: .3s;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: textfield;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }
`;

export default FileFieldWrapper;

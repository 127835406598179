import { styled } from "styled-components";

const FormUserWrapper = styled.div`
  flex-grow: 1;
  padding-top: 2rem;
  max-height: 100vh;
  overflow-y: auto;

  .page-title {
    margin-bottom: 2rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 1rem;

    .section-title {
      font-weight: bold;
    }

    form {
      row-gap: 2rem;

      .btn-container-form-send {
        display: flex;
        width: fit-content;
        margin-left: auto;
      }

      .info-icon {
        max-width: 19px;
        stroke: none;
      }
    }
  }
`;

export default FormUserWrapper;

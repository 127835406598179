import styled from "styled-components";

const TaxCollectionWrapper = styled.div`
  &.tax-collection-component {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;

    .tax-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 15px;
    }
  }
`;

export default TaxCollectionWrapper;

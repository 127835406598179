import React, { useMemo } from 'react';
import VisualizerView from './visualizer.view';
import IVisualizer from './visualizer.props';
import { productPercentualFormula, productSumFormula } from '../../library';

const Visualizer: React.FC<IVisualizer> = (props) => {
  const {
    container,
    products,
  } = props;

  const { productWeight, productVolume } = useMemo<{ productWeight: number, productVolume: number }>(() => productSumFormula({ products }), [products]);
  const percentual = useMemo<number>(() => productPercentualFormula({ productWeight, productVolume, container }), [container, productVolume, productWeight])

  return <VisualizerView 
    container={container}
    productWeight={productWeight}
    productVolume={productVolume}
    percentual={percentual}
  />;
};

export default Visualizer;

import React, { useCallback, useState } from 'react';
import GuestFormView from './guest-form.view';
import IGuestForm from './guest-form.props';
import { useRenderGuestFormFields } from '../../Hooks';
import { guestService } from '../../Services';

const GuestForm: React.FC<IGuestForm> = () => {
  const {
    renderGuestFormFields,
    guestBody,
  } = useRenderGuestFormFields();

  const [sucessRequest, setSucessRequest] = useState<boolean>(false);
  const [failureRequest, setFailureRequest] = useState<boolean>(false);

  const handleSendGuestForm = useCallback(() => {
    if (sucessRequest) return;

    try {
      guestService.post(guestBody);
      setSucessRequest(true);
    } catch (err) {
      setFailureRequest(true)
    }
  }, [guestBody, sucessRequest])

  return <GuestFormView
    renderGuestFormFields={renderGuestFormFields}
    sendGuestForm={handleSendGuestForm}
    openSuccess={sucessRequest}
    openFailure={failureRequest}
    setFailureRequest={setFailureRequest}
    setSuccessRequest={setSucessRequest}
  />;
};

export default GuestForm;

import styled from 'styled-components';

const TaxUnitWrapper = styled.div`
  &.tax-unit-component{
    display: flex;
    gap: 1em;
    font-family: Roboto;

    .currency-container {
      display: flex;
      align-items: center;

      .currency {
        color: #FFF;
        font-size: 20px;
        font-weight: 400;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: .25rem;

      .name {
        font-weight: bold;
        font-size: 10px;
        color: #fff;
        font-weight: 700;
      }

      .value-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .variation {
          font-family: Roboto;
          font-size: 10px;
          font-weight: 400;

          &.danger {
            color: #DE2C2C;
          }

          &.success{
            color: #4BB543;
          }
        }

        .value {
          font-size: 14px;
          color: #fff;
          font-weight: 400;
        }
      }

    }
  }
`;

export default TaxUnitWrapper;

import axios from "axios";
import { ITax, ITaxKeys } from "../Types";

const taxService = {
  get: () => {
    const taxRequest = axios.get(
      "https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL,CNY-BRL,GBP-BRL,ARS-BRL"
    );

    return taxRequest.then((taxes) => {
      const quotes = taxes.data;

      const taxesArray: ITax[] = [];

      Object.keys(quotes).forEach(function (key) {
        taxesArray.push({ name: key as ITaxKeys, value: quotes[key].bid, variation: quotes[key].pctChange });
      });

      return Promise.resolve(taxesArray);
    });
  }
};

export default taxService;

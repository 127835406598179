import React, { useCallback, useState } from 'react';
import AdminContainerCardView from './admin-container-card.view';
import IAdminContainerCard from './admin-container-card.props';
import { containerService } from '../../Services';
import { useNavigate } from 'react-router-dom';

const AdminContainerCard: React.FC<IAdminContainerCard> = (props) => {
  const { container, onDelete } = props;

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    containerService.delete(container.id).then(() => onDelete());
  }, [container.id, onDelete]);

  const toggleConfirmDelete = useCallback(() => {
    setConfirmDelete(current => !current);
  }, [])

  const handleEditRedirect = useCallback(() => {
    navigate(`edit/${container.id}`);
  }, [navigate, container.id]);

  return <AdminContainerCardView 
    container={container}
    confirmDelete={confirmDelete}
    handleDelete={handleDelete}
    toggleConfirmDelete={toggleConfirmDelete}
    handleEditRedirect={handleEditRedirect}
  />;
};

export default AdminContainerCard;

import React, { useMemo } from 'react';
import MarkerView from './marker.view';
import IMarker from './marker.props';
import { generateMarkerNameColor } from '../../../../Library';
import { PLANE_SVG, SHIP_SVG } from '../../constants';

const Marker: React.FC<IMarker> = (props) => {
  const {
    marker,
    onClickMarker
  } = props;

  const markerNameColor = useMemo(() => generateMarkerNameColor(), []);

  const iconPath = useMemo(() => {
    if (marker.type === 'airport') return PLANE_SVG
    if (marker.type === 'port') return SHIP_SVG
    return "";
  }, [marker]);

  const markerTitle = useMemo(() => {
    if (marker.type === 'airport') return `${marker.label}`
    if (marker.type === 'port') return `${marker.label}`
    return "";
  }, [marker])

  return (
    <MarkerView
      marker={marker}
      iconPath={iconPath}
      markerTitle={markerTitle}
      onClickMarker={onClickMarker}
      markerNameColor={markerNameColor} />
  );
};

export default Marker;

import React from "react";
import CalculatorWrapper from "./calculator.style";
import { IContainer } from "../../Types";
import { InputField } from "../Fields";
import { BiCuboid, BiRename, BiDumbbell } from "react-icons/bi";
import { CustomButton } from "../CustomButton";
import { IProduct, Product } from "./components/Product";
import { Visualizer } from "./components/Visualizer";

interface IProps {
  container?: IContainer;
  handleAddProduct: (event: React.FormEvent) => void;
  onProductNameChange: (event: string) => void;
  productName: string;
  productNameError: boolean;
  onProductWeightChange: (event: string) => void;
  productWeight: string;
  productWeightError: boolean;
  onProductVolumeChange: (event: string) => void;
  productVolume: string;
  productVolumeError: boolean;
  products: Omit<IProduct, "onDelete">[];
  onProductDelete: (index: number) => void;
}

const CalculatorViewNoMemo: React.FC<IProps> = (props) => (
  <CalculatorWrapper className="calculator-component bg-accent">
    {Boolean(props.container) && (
      <div className="container">
        <div className="title">
          <h2>Calculadora</h2>
          <span>{props.container?.name}</span>
        </div>

        <div className="calculator-wrapper">
          <div className="calculator">
            <form className="calculator-form" onSubmit={props.handleAddProduct}>
              <div className="fields">
                <InputField
                  id="container-calculator-product-name"
                  label="Nome"
                  onChange={props.onProductNameChange}
                  value={props.productName}
                  error={props.productNameError}
                  icon={<BiRename />}
                ></InputField>

                <InputField 
                  id="container-calculator-product-weight"
                  label="Peso (kg)"
                  onChange={props.onProductWeightChange}
                  value={props.productWeight}
                  error={props.productWeightError}
                  icon={<BiDumbbell />}
                  type="number"
                ></InputField>

                <InputField 
                  id="container-calculator-product-volume"
                  label="Volume (m³)"
                  onChange={props.onProductVolumeChange}
                  value={props.productVolume}
                  error={props.productVolumeError}
                  icon={<BiCuboid />}
                  type="number"
                ></InputField>
              </div>

              <button type="submit" hidden></button>
              <CustomButton className="calculator-form-btn" onClick={props.handleAddProduct}>Adicionar produto</CustomButton>
            </form>

            <div className="calculator-products">
              {
                props.products.map((product: Omit<IProduct, "onDelete">, index: number) => 
                <Product
                  key={index}
                  name={product.name} 
                  weight={product.weight} 
                  volume={product.volume} 
                  onDelete={() => props.onProductDelete(index)}
                />)
              }
            </div>
          </div>

          <Visualizer
            container={props.container}
            products={props.products}
          />
        </div>
      </div>
    )}
  </CalculatorWrapper>
);

const CalculatorView = React.memo(CalculatorViewNoMemo);
export default CalculatorView;

import { styled } from "styled-components";

const ContainerCollectionWrapper = styled.div`
  &.container-collection-component {
    display: flex;

    .container-card-component {
      max-width: 100%;
    }

    .alice-carousel__stage-item {
      padding-right: 0.5rem;
      max-width: 315px;
    }
  }
`;

export default ContainerCollectionWrapper;

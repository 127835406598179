import React, { useCallback, useMemo, useRef, useState } from 'react';
import IncotermsView from './incoterms.view';
import IIncoterms from './incoterms.props';
import { incotermsData, incotermsStages } from './mock';
import { IIncotermsData } from './types';
import { DEFAULT_INCOTERMS_ACRONYM } from './constants';

import {
  useFloating,
  autoUpdate,
  size,
  flip,
  shift,
  offset,
  useFocus,
  useDismiss,
  useInteractions,
  useClick
} from '@floating-ui/react';

const Incoterms: React.FC<IIncoterms> = () => {
  const [selectedIncotermAcronym, setSelectedIncotermAcronym] = useState(DEFAULT_INCOTERMS_ACRONYM);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  const currentIncoterm = useMemo<IIncotermsData>(() => incotermsData[selectedIncotermAcronym], [selectedIncotermAcronym]);
  const incotermsAcronym = useMemo<string[]>(() => Object.values(incotermsData)?.map(incoterm => incoterm.acronym), []);

  const floatingUiSizeApplicator = (props: any) => {
    const { rects, elements } = props;
    Object.assign(elements.floating.style, { width: `${rects.reference.width}px` })
  };

  const { refs: floatingRefs, floatingStyles, context } = useFloating({
    open: dropdownOpen,
    onOpenChange: setDropdownOpen,
    middleware: [flip(), shift(), offset(10), size({ apply: floatingUiSizeApplicator })],
    whileElementsMounted: autoUpdate,
  });

  const aaaa = useCallback((value: any) => {
    setMobileDropdownOpen(value)
  }, [])

  const { refs: mobileFloatingRefs, floatingStyles: mobileFloatingStyles, context: mobileContext } = useFloating({
    open: mobileDropdownOpen,
    onOpenChange: aaaa,
    middleware: [flip(), shift(), offset(10), size({ apply: floatingUiSizeApplicator })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context, { keyboardHandlers: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);

  const mobileClick = useClick(mobileContext, { keyboardHandlers: false });
  const mobileFocus = useFocus(mobileContext);
  const mobileDismiss = useDismiss(mobileContext);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, focus, dismiss]);
  const { getReferenceProps: getMobileReferenceProps, getFloatingProps: getMobileFloatingProps } = useInteractions([mobileClick, mobileFocus, mobileDismiss]);

  const handleOnChangeIncoterm = useCallback((acronym: string) => {
    setSelectedIncotermAcronym(acronym);
    setDropdownOpen(false);
    setMobileDropdownOpen(false);
  }, [])

  const handleOnDropdownClick = useCallback(() => {
    setDropdownOpen(dropdownOpen => !dropdownOpen);
    setMobileDropdownOpen(dropdownOpen => !dropdownOpen);
  }, [])

  return <IncotermsView
    dropdownOpen={dropdownOpen}
    mobileDropdownOpen={mobileDropdownOpen}
    currentIncoterm={currentIncoterm}
    incotermsOptions={incotermsAcronym}
    incotermsStages={incotermsStages}
    listInteractionRefs={floatingRefs}
    floatingStyles={floatingStyles}
    getReferenceProps={getReferenceProps}
    getFloatingProps={getFloatingProps}
    mobileFloatingRefs={mobileFloatingRefs}
    mobileFloatingStyles={mobileFloatingStyles}
    getMobileReferenceProps={getMobileReferenceProps}
    getMobileFloatingProps={getMobileFloatingProps}
    onDropdownClick={handleOnDropdownClick}
    onChangeIncoterm={handleOnChangeIncoterm}
  />;
};

export default Incoterms;

import React from 'react';
import ProductView from './product.view';
import IProduct from './product.props';

const Product: React.FC<IProduct> = (props) => {
  const {
    name,
    weight,
    volume,
    onDelete,
  } = props;
  return <ProductView
    name={name}
    weight={weight}
    volume={volume}
    onDelete={onDelete}
  />;
};

export default Product;

const formatPhone = (stringPhone: string): string => {
  const phone = stringPhone.replace(/\D/g, '');
  switch (phone.length) {
    case 13: return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
    case 12: return phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
    case 11: return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    case 10: return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    case  9: return phone.replace(/(\d{5})(\d{4})/, '$1-$2');
    case  8: return phone.replace(/(\d{4})(\d{4})/, '$1-$2');
    default: return phone;
  }
};

export default formatPhone;
import React from 'react';
import './App.scss';
import RoutesProvider from './routes';
import { MuiConfig } from './Plugins';
import { ThemeProvider } from '@mui/material';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={MuiConfig.theme}>
        <RoutesProvider />
      </ThemeProvider>
    </div>
  );
}

export default App;

import { useCallback, useEffect, useState } from "react";
import { CustomButton, InputField } from "../../Components";
import FormUserWrapper from "./style";
import { userService } from "../../Services";
import { BiMailSend, BiUserCircle } from "react-icons/bi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { useValidateToken } from "../../Hooks";

const FormUser: React.FC = () => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [editing, setEditing] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useValidateToken();

  useEffect(() => {
    const mode = location.pathname;
    const { id } = params;
    setUserId(id);
    if (mode.startsWith(`/dashboard/users/edit/${id}`)) {
      setPageTitle("Editar usuário");
      setEditing(true)
    } else if (mode.startsWith("/dashboard/users/new")) {
      return setPageTitle("Criar usuário");
    } else return;

    if (!id) return;
    userService.get(id).then(({ data: user }) => {
      if (!user) return;
      setName(user.name);
      setEmail(user.email);
    });
  }, [location.pathname, params]);

  const handleChangeName = useCallback(
    (value: string) => {
      setNameError(false);
      setName(value);
    },
    [setName]
  );
  const handleChangeEmail = useCallback(
    (value: string) => {
      setEmailError(false);
      setEmail(value);
    },
    [setEmail]
  );
  const handleChangePassword = useCallback(
    (value: string) => {
      setPasswordError(false);
      setPassword(value);
    },
    [setPassword]
  );

  const isValid = useCallback(() => {
    let isValid = true;
    if (name === "") {
      setNameError(true);
      isValid = false;
    }
    if (email === "") {
      setEmailError(true);
      isValid = false;
    }
    if (password === "") {
      setPasswordError(true);
      isValid = false;
    }
    return isValid;
  }, [name, email, password]);

  const onBack = useCallback(() => {
    navigate(-1)
  }, [navigate]);

  const handleEditUser = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (success) return;
      const body = { name, email, password };
      if (!isValid()) return;
      setLoading(true);
      if (editing) {
        if (userId === undefined) return;
        userService.put(userId, body).then(() => { 
          setLoading(false)
          setSuccess(true);
        });
      } else {
        userService.post(body).then(() => { 
          setLoading(false)
          setSuccess(true);
        });
      }
    },
    [
      name,
      email,
      editing,
      userId,
      success,
      isValid
    ]
  );

  return (
    <FormUserWrapper className="container">
      <h1 className="page-title">{pageTitle}</h1>

      <div className="form-wrapper">
        <form onSubmit={handleEditUser} className="row">
          <div className="col-12">
            <InputField
              id="guest-name"
              value={name}
              label="Nome completo"
              icon={<BiUserCircle />}
              onChange={handleChangeName}
              error={nameError}
            />
          </div>
          <div className="col-12">
            <InputField
              id="guest-email"
              value={email}
              label="E-mail"
              icon={<BiMailSend />}
              onChange={handleChangeEmail}
              error={emailError}
            />
          </div>
          <div className="col-12">
            <InputField
              id="guest-password"
              value={password}
              label="Senha"
              password
              onChange={handleChangePassword}
              error={passwordError}
            />
          </div>

          <CustomButton
            className="btn-guest-form-send col-12"
            type="submit"
            onClick={handleEditUser}
            loading={loading}
            color={success ? 'success' : 'primary'}
          >
            {success ? 'Salvo!' : 'Salvar'}
          </CustomButton>

          {success && <CustomButton
            className="btn-guest-form-send col-12"
            onClick={onBack}
          >
            Voltar
          </CustomButton>}

          {emailError && nameError && (
            <div className="col-12">
              <Alert severity="error">Campos precisam estar preenchidos</Alert>
            </div>
          )}
        </form>
      </div>
    </FormUserWrapper>
  );
};

export default FormUser;

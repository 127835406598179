import { styled } from "styled-components";

const CustomButtonWrapper = styled.div`
  &.custom-button-component {
    button {
      padding: 0.75rem 2.5rem;
      font-size: 1rem;
      border-radius: 0;
      width: 100%;
      text-transform: none;
      border-radius: 5px;
    }
  }
`;

export default CustomButtonWrapper;

import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IHeadCell } from "../Types";
import ROWS_PER_PAGE from "../Constants/ROWS_PER_PAGE";

interface IProps<T> {
  emptyRows: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  handleRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  page: number;
  headCells: IHeadCell<T>[];
  total: number;
  rows: T[];
  rowsPerPage: number;
  renderCellValue: (row: T, headCell: IHeadCell<T>) => JSX.Element;
}

const CustomTableViewNoMemo = <T extends unknown>(props: IProps<T>) => (
  <Box sx={{ width: "100%" }}>
    <Paper sx={{ width: "100%", mb: 2 }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {props.headCells?.map((headCell: IHeadCell<T>, index: number) => (
                <TableCell
                  key={`head-cell-${index}`}
                  align="left"
                  padding="normal"
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows?.map((row: T, indexRow: number) => (
              <TableRow hover tabIndex={-1} key={`table-row-${indexRow}`}>
                {props.headCells?.map(
                  (headCell: IHeadCell<T>, indexColumn: number) => (
                    <TableCell
                      key={`table-cell-${indexRow}-${indexColumn}`}
                      align="left"
                      padding="normal"
                      sx={{ verticalAlign: "baseline", whiteSpace: headCell.phone ? 'nowrap' : 'normal' }}
                    >
                      {props.renderCellValue(row, headCell)}
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
            {props.emptyRows > 0 && (
              <TableRow style={{ height: 53 * props.emptyRows }}>
                <TableCell colSpan={props.headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.total}
        rowsPerPage={props.rowsPerPage}
        rowsPerPageOptions={ROWS_PER_PAGE}
        page={props.page}
        onRowsPerPageChange={props.handleRowsPerPageChange}
        onPageChange={props.handleChangePage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} - ${to} de ${count}`
        }
        labelRowsPerPage="Linhas por página"
      />
    </Paper>
  </Box>
);

const CustomTableView = React.memo(CustomTableViewNoMemo);
export default CustomTableView;

import { styled, css } from "styled-components";
import { BREAKPOINTS, HEADER_HEIGHT } from "../../Constants";

const HeaderWrapper = styled.div`
  ${() => css`
    &.header-component {
      width: 100%;
      max-height: ${HEADER_HEIGHT}px;
      top: 0;
      background: #F9F9F9;
      box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.12);
      display: flex;
      padding: 16px 128px;
      position: fixed;
      z-index: 1000;

      @media (max-width: ${BREAKPOINTS.md}px) {
          width: 100%;
          background-color: #fff;

          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 20px;

          z-index: 1000;
      }

      .container {
        display: flex;
        width: 100% !important;
      }

      .content {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .header-menu-icon {
          display: none;

          @media (max-width: ${BREAKPOINTS.md}px) {
            display: flex;
            align-items: center;
          }
        }

        .option-container {
          display: flex;
          align-items: center;
          gap: .5rem;
          height: 100%;

          @media (max-width: ${BREAKPOINTS.md}px) {
            display: none;
          }
        }

        nav.option {
          padding:2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: fit-content;
          font-weight: 400;
          cursor: pointer;
          height: 100%;

          @media (max-width: ${BREAKPOINTS.md}px) {
            display: none;
          }
        }

        .option-link {
          display: block;
          position: relative;
          padding: 0.2em 0;
        }

        .option-link::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.1em;
          background-color: #1C51E6;
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
        }

        .option-link::after {
          opacity 1;
          transform: scale(0);
          transform-origin: center;
        }

        .option-link:hover::after,
        :focus::after {
          opacity: 1;
          transform: translate3d(0, 0.2em, 0);
        }

        .option-link:hover::after,
        .option-link:focus::after{
          transform: scale(1);
        }

        .logo-container {
          width: fit-content;
          display: flex;
          align-items: center;

          img {
            width: 98.697px;
            height: 50px;
            cursor: pointer;
          }

          @media (max-width: ${BREAKPOINTS.md}px) {
            margin-right: 20px;
            max-height: 40px;
          }
        }
      }
    }
  `}
`;

export default HeaderWrapper;

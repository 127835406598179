import { styled } from "styled-components";

const GuestFormWrapper = styled.form`
  &.guest-form-component{
    background: white;
    padding: 2rem;

    .row {
      row-gap: 2rem;
    }

    .btn-guest-form-send {
      margin-top: 2rem;
      display: flex;
      width: fit-content;
      margin-left: auto;
    }
  }
`;

export default GuestFormWrapper;

import { useCallback } from "react";
import { taxService } from "../Services";
import { ITax } from "../Types";

const useGetTaxes = () => {
  const getTaxes = useCallback((): Promise<ITax[]> => {
    return taxService.get()
      .then(taxes => {
        return Promise.resolve(taxes);
      })
  }, []);

  return getTaxes;
}

export default useGetTaxes;
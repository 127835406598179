import { styled } from "styled-components";

const PhoneFieldWrapper = styled.div`
  &.phone-field-component {
    box-shadow: 0 0 0 2px var(--gray-2);
    padding: 0.5rem 1rem;
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    transition: 0.3s;
    border-radius: 5px;

    &.error {
      box-shadow: 0 0 0 2px var(--error);
      transition: 0.3s;

      svg {
        fill: var(--error);
        stroke: var(--error);
        transition: 0.3s;
      }

      .label-field label {
        color: var(--error);
        transition: 0.3s;
      }

      &.active {
        box-shadow: 0 0 0 2px var(--error);
        transition: 0.3s;

        svg {
          fill: var(--error);
          transition: 0.3s;
        }
        label {
          color: var(--error);
          transition: 0.3s;
        }
      }
    }

    .icon {
      max-height: 2.5rem;
      height: 2.5rem;
      display: flex;
      align-items: center;

      svg {
        transition: 0.3s;
        fill: var(--gray-1);
        stroke: var(--gray-1);
        height: 24px;
        width: 24px;
      }
    }

    .label-field {
      position: relative;
      width: 100%;

      label {
        position: absolute;
        transition: 0.3s;
        inset: 0;
        color: var(--gray-1);
        display: flex;
        align-items: center;
        height: 2.5rem;
      }

      .phone-field-mui {
        transition: 0.3s;
        width: 100%;

        input {
          font-weight: bold;
        }

        > div {
          height: 100%;
        }

        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        ::-webkit-scrollbar-thumb {
          background: #aaa;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #888;
        }
      }
    }

    &.active,
    &.filled {
      label {
        transform: translateY(-13px);
        font-size: 0.75rem;
        transition: 0.3s;
      }
      .phone-field-mui {
        transform: translateY(13px);
        transition: 0.3s;
      }
    }

    &.active {
      box-shadow: 0 0 0 2px var(--primary);
      transition: 0.3s;

      svg {
        fill: var(--primary);
        transition: 0.3s;
      }
      label {
        color: var(--primary);
        transition: 0.3s;
      }
    }
  }
`;

export default PhoneFieldWrapper;

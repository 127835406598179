import { useCallback, useEffect, useState } from "react";
import { UserCard } from "../../Components";
import { IUser } from "../../Types";
import UsersWrapper from "./style";
import { userService } from "../../Services";
import { BiPlusCircle } from "react-icons/bi";
import { Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useValidateToken } from "../../Hooks";

const Users: React.FC = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const navigate = useNavigate();
  const [page] = useState(1);

  useValidateToken();

  const requestUsers = useCallback(() => {
    userService.getAll(page).then((paginationUser) => {
      const {
        data: users
      } = paginationUser;

      setUsers(users ?? []);
    });
  }, [page])

  useEffect(requestUsers, [page, requestUsers])

  const handleDelete = useCallback(() => requestUsers(), [requestUsers])

  const handleRedirectNewUser = useCallback(() => {
    navigate("new");
  }, [navigate]);

  return (
    <UsersWrapper className="container">
      <h1 className="page-title">Usuários</h1>

      <div className="user-container">
        {users.map((user: IUser, index: number) => (
          <UserCard key={`user-card-${index}`} user={user} onDelete={handleDelete} />
        ))}
        <Tooltip title="Adicionar novo usuário">
          <Box className="cta-create-new clickable" onClick={handleRedirectNewUser}>
            <BiPlusCircle size={24} />
          </Box>
        </Tooltip>
      </div>
    </UsersWrapper>
  );
};

export default Users;

import { IGuest } from "../Types";

const guests: IGuest[] = [
  {
    companyName: "Voomm",
    createdAt: "4/7/2023",
    email: "awalesa0@people.com.cn",
    lineOfBusiness: "Garden",
    locality: "Carmo do Paranaíba",
    message: "Proin leo odio, porttitor id, consequat in, consequat ut, nulla. Sed accumsan felis. Ut at dolor quis odio consequat varius.",
    name: "Aarika Walesa",
    phone: "63993192462",
    id: "fcdcc910-8947-4373-a5f3-0eb4f26a9945",
  },
  {
    companyName: "Topdrive",
    createdAt: "7/20/2022",
    email: "mfilan1@t-online.de",
    lineOfBusiness: "Kids",
    locality: "Paccha",
    message: "Curabitur gravida nisi at nibh. In hac habitasse platea dictumst. Aliquam augue quam, sollicitudin vitae, consectetuer eget, rutrum at, lorem.",
    name: "Marlyn Filan",
    phone: "11976978271",
    id: "ed4cef1f-03db-41d6-b12a-ae92c8ec5f7f",
  },
  {
    companyName: "Skimia",
    createdAt: "11/28/2022",
    email: "cdello2@ocn.ne.jp",
    lineOfBusiness: "Shoes",
    locality: "Shuangshan",
    message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.\n\nVestibulum quam sapien, varius ut, blandit non, interdum in, ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis faucibus accumsan odio. Curabitur convallis.\n\nDuis consequat dui nec nisi volutpat eleifend. Donec ut dolor. Morbi vel lectus in quam fringilla rhoncus.",
    name: "Cherrita Dello",
    phone: "77983725053",
    id: "5a9d666b-354f-4632-ad48-638e7f87d56c",
  },
  {
    companyName: "Wikido",
    createdAt: "4/14/2023",
    email: "mmattia3@mashable.com",
    lineOfBusiness: "Tools",
    locality: "Skien",
    message: "Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.\n\nMaecenas ut massa quis augue luctus tincidunt. Nulla mollis molestie lorem. Quisque ut erat.\n\nCurabitur gravida nisi at nibh. In hac habitasse platea dictumst. Aliquam augue quam, sollicitudin vitae, consectetuer eget, rutrum at, lorem.",
    name: "Myca Mattia",
    phone: "67931400727",
    id: "d6c00f97-2863-4551-8991-50752e4a23c2",
  },
  {
    companyName: "Yadel",
    createdAt: "3/15/2023",
    email: "oscottini4@jugem.jp",
    lineOfBusiness: "Movies",
    locality: "Baiyang",
    message: "Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.",
    name: "Obadias Scottini",
    phone: "84949356070",
    id: "9275d4da-f875-41d8-8902-3fcdd05a3058",
  },
  {
    companyName: "Twimm",
    createdAt: "7/20/2022",
    email: "lwenger5@addtoany.com",
    lineOfBusiness: "Home",
    locality: "Punta Gorda",
    message: "Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.\n\nCras mi pede, malesuada in, imperdiet et, commodo vulputate, justo. In blandit ultrices enim. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.\n\nProin interdum mauris non ligula pellentesque ultrices. Phasellus id sapien in sapien iaculis congue. Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl.",
    name: "Loralee Wenger",
    phone: "40943913772",
    id: "98a5074a-706b-4f30-8cfa-81eacd9ed70f",
  },
  {
    companyName: "Skyvu",
    createdAt: "3/3/2023",
    email: "tserck6@java.com",
    lineOfBusiness: "Grocery",
    locality: "Luklukan",
    message: "Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti.\n\nNullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris.\n\nMorbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.",
    name: "Toby Serck",
    phone: "65946733258",
    id: "9c7aa476-262e-4e8f-a045-2aaaee557ee5",
  },
  {
    companyName: "Buzzbean",
    createdAt: "9/13/2022",
    email: "epatrono7@businesswire.com",
    lineOfBusiness: "Computers",
    locality: "Itaúna",
    message: "Etiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem.",
    name: "Eldin Patrono",
    phone: "34998141762",
    id: "3339d0d5-1fb9-4b45-81cc-1e0059617e44",
  },
  {
    companyName: "Talane",
    createdAt: "12/24/2022",
    email: "mcumberlidge8@hubpages.com",
    lineOfBusiness: "Movies",
    locality: "Atyrá",
    message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin risus. Praesent lectus.\n\nVestibulum quam sapien, varius ut, blandit non, interdum in, ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis faucibus accumsan odio. Curabitur convallis.",
    name: "Mella Cumberlidge",
    phone: "60918185800",
    id: "01c4fdfa-1654-4351-8b63-48db13e64c7f",
  },
  {
    companyName: "Wikido",
    createdAt: "3/12/2023",
    email: "djales9@angelfire.com",
    lineOfBusiness: "Baby",
    locality: "Mulan",
    message: "Morbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.\n\nFusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem.\n\nSed sagittis. Nam congue, risus semper porta volutpat, quam pede lobortis ligula, sit amet eleifend pede libero quis orci. Nullam molestie nibh in lectus.",
    name: "Debor Jales",
    phone: "96945720307",
    id: "349592eb-e63f-4cdf-bcf4-37620dd9d510",
  },
  {
    companyName: "Miboo",
    createdAt: "8/20/2022",
    email: "chardwickea@newyorker.com",
    lineOfBusiness: "Toys",
    locality: "Bugko",
    message: "Pellentesque at nulla. Suspendisse potenti. Cras in purus eu magna vulputate luctus.\n\nCum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.\n\nEtiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem.",
    name: "Conroy Hardwicke",
    phone: "56909309679",
    id: "b83d4abc-185f-4227-ac6e-1a2553df39b8",
  },
  {
    companyName: "Dabvine",
    createdAt: "10/10/2022",
    email: "awellanb@joomla.org",
    lineOfBusiness: "Tools",
    locality: "Azogues",
    message: "Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum.",
    name: "Alard Wellan",
    phone: "16959194234",
    id: "e62a10aa-7f84-4081-98f9-ab6ada2d43b2",
  },
  {
    companyName: "Avamm",
    createdAt: "1/23/2023",
    email: "vmcilvaneyc@discovery.com",
    lineOfBusiness: "Industrial",
    locality: "Outeiro",
    message: "Quisque porta volutpat erat. Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla. Nunc purus.\n\nPhasellus in felis. Donec semper sapien a libero. Nam dui.",
    name: "Vivi McIlvaney",
    phone: "62972253920",
    id: "341c942f-d8ba-474c-ba0b-c8af489561f1",
  },
  {
    companyName: "Tagopia",
    createdAt: "6/2/2023",
    email: "dcoldhamd@dot.gov",
    lineOfBusiness: "Kids",
    locality: "Washington",
    message: "Aliquam quis turpis eget elit sodales scelerisque. Mauris sit amet eros. Suspendisse accumsan tortor quis turpis.",
    name: "Deni Coldham",
    phone: "20926700520",
    id: "f3155fe8-0174-49c3-b5b6-c97be6174ef6",
  },
  {
    companyName: "Tanoodle",
    createdAt: "6/13/2022",
    email: "mbernolete@rediff.com",
    lineOfBusiness: "Beauty",
    locality: "Sihai",
    message: "Duis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus.",
    name: "Maribel Bernolet",
    phone: "26935062018",
    id: "520c2e90-0979-4309-a324-10800233065d",
  },
  {
    companyName: "Demizz",
    createdAt: "5/28/2023",
    email: "hcridlanf@typepad.com",
    lineOfBusiness: "Movies",
    locality: "Khān Yūnis",
    message: "Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit.\n\nDonec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.\n\nDuis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus.",
    name: "Hube Cridlan",
    phone: "23902970709",
    id: "199974a9-e768-48e5-859e-4e25d6fdb5e4",
  },
  {
    companyName: "Topdrive",
    createdAt: "10/25/2022",
    email: "cpaulssong@bloglines.com",
    lineOfBusiness: "Games",
    locality: "Petaẖ Tiqwa",
    message: "Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti.",
    name: "Cristobal Paulsson",
    phone: "32994242246",
    id: "7e6384a6-9711-4744-a4c9-f9026422277c",
  },
  {
    companyName: "Linkbuzz",
    createdAt: "11/7/2022",
    email: "gsharrierh@quantcast.com",
    lineOfBusiness: "Computers",
    locality: "Susiec",
    message: "Suspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.",
    name: "Gare Sharrier",
    phone: "48972687310",
    id: "d6097a0c-f3d6-4d48-adeb-37901afd88ba",
  },
  {
    companyName: "Riffpath",
    createdAt: "11/1/2022",
    email: "bshellardi@studiopress.com",
    lineOfBusiness: "Toys",
    locality: "Moss",
    message: "Fusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl. Nunc rhoncus dui vel sem.\n\nSed sagittis. Nam congue, risus semper porta volutpat, quam pede lobortis ligula, sit amet eleifend pede libero quis orci. Nullam molestie nibh in lectus.",
    name: "Brittney Shellard",
    phone: "41906390759",
    id: "1c74e702-a518-4597-8476-6444058a972c",
  },
  {
    companyName: "Camimbo",
    createdAt: "11/16/2022",
    email: "pdonnellanj@unc.edu",
    lineOfBusiness: "Electronics",
    locality: "Yinping",
    message: "Duis bibendum, felis sed interdum venenatis, turpis enim blandit mi, in porttitor pede justo eu massa. Donec dapibus. Duis at velit eu est congue elementum.\n\nIn hac habitasse platea dictumst. Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante. Nulla justo.",
    name: "Pippo Donnellan",
    phone: "70924979708",
    id: "1ea95558-8e27-420d-bc22-dedd24a00830",
  },
];

export default guests;
import { styled, css } from "styled-components";

const DrawerOptionsWrapper = styled.div`
  ${() => css`
    &.drawer-options-component {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .drawer-list {
        width: 200px;
        padding: 0;
      }

      .drawer-option {
        padding: 10px;
        list-style: none;

        display: flex;
        align-items: center;

        svg {
          margin-right: 1em;
        }

        a {
          display: flex;
          align-items: center;
        }
      }

      .logo-container {
        max-width: 200px;
        padding: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 50%;
        }
      }
    }
  `}
`;

export default DrawerOptionsWrapper;

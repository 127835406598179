import React from 'react';
import CustomButtonWrapper from './custom-button.style';
import { Button, CircularProgress } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  loading?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type: "button" | "submit" | "reset";
  progressColor: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

const CustomButtonViewNoMemo: React.FC<IProps> = (props) => (
  <CustomButtonWrapper className={['custom-button-component', props.className].join(' ')}>
    <Button
      variant="contained"
      color={props.color}
      onClick={props.onClick}
      type={props.type}
    >{props.loading
      ? <CircularProgress color={props.progressColor} size={28} />
      : props.children}
    </Button>
  </CustomButtonWrapper>
);

const CustomButtonView = React.memo(CustomButtonViewNoMemo);
export default CustomButtonView;

import styled from "styled-components";
import "../../Assets/styles/variables.scss";

import { BREAKPOINTS, HEADER_HEIGHT } from "../../Constants";

const MainWrapper = styled.div`
  /* padding-top: ${HEADER_HEIGHT}px; */
  position: relative;

  section {
    padding-top: 3rem;
    padding-bottom: 3rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 2rem;
    }

    @media (max-width: 720px) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding: 1rem;
      }
    }
  }

  .body-container {
    margin-top: 82px;
  }

  .services .services-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media (min-width: ${BREAKPOINTS.lg}px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .slogan {
    display: flex;
    padding: 1rem 0;

    &.primary-to-accent {
      background: linear-gradient(
        180deg,
        var(--primary) 50%,
        var(--accent) 50%
      );
    }

    .slogan-text {
      background-color: white;
      color: var(--dark-primary);
      width: fit-content;
      margin: 0 auto;
      padding: 0.5rem 2rem;
    }
  }

  .map-container {
    height: 100vh;
  }

  .text-align-center {
    text-align: center;
  }
`;

export default MainWrapper;

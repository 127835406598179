import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InputFieldView from './input-field.view';
import IInputField from './input-field.props';
import { BiLock, BiLockOpen } from 'react-icons/bi';
import { FiPackage } from 'react-icons/fi';

const InputField: React.FC<IInputField> = (props) => {
  const {
    icon: iconByProp,
    id,
    label,
    type: typeByProp = 'text',
    value,
    className,
    error,
    onChange: onChangeProps,
    password = false,
  } = props;

  const [text, setText] = useState<string>("");
  const [showText, setShowText] = useState<boolean>(!password);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  const type = useMemo<string>(() => {
    if (password) return showText ? "text" : "password";
    return typeByProp;
  }, [typeByProp, password, showText]);

  const icon = useMemo<JSX.Element>(() => {
    if (password) return showText ? <BiLockOpen /> : <BiLock />;
    return iconByProp ?? <FiPackage />;
  }, [iconByProp, showText, password]);

  const positionHelperClass = useMemo<string>(() => {
    const classes = [];
    if (isFocused) classes.push('active');
    if (isFilled) classes.push('filled');
    return classes.join(' ');
  }, [isFocused, isFilled]);

  const toggleShowText = useCallback(() => setShowText(!showText), [showText])
  const onBlur = useCallback(() => setIsFocused(false), []);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeProps(value);
    setIsFilled(value !== "");
    setText(value ?? "");
  }, [onChangeProps, setIsFilled, setText]);

  useEffect(() => {
    setIsFilled(value !== "" && value !== undefined);
  }, [onChangeProps, setIsFilled, setText, value]);

  return <InputFieldView
    icon={icon} 
    id={id} 
    label={label}
    type={type}
    value={value ?? text}
    onBlur={onBlur}
    onFocus={onFocus}
    positionHelperClass={positionHelperClass}
    onChange={onChange}
    className={className}
    error={error}
    password={password}
    showText={showText}
    toggleShowText={toggleShowText}
  />;
};

export default InputField;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Calculator, Main, Login, Dashboard, Guests, Containers, Users, FormUser, FormContainer } from './Pages';

const RoutesProvider = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/container/:id' element={<Calculator />} />
                <Route path='/admin' element={<Login />} />
                <Route path='/dashboard' element={<Dashboard />}>
                    <Route index element={<Guests />} />
                    <Route path='guests' element={<Guests />} />
                    <Route path='containers' element={<Containers />} />
                    <Route path='containers/new' element={<FormContainer />} />
                    <Route path='containers/edit/:id' element={<FormContainer />} />
                    <Route path='users' element={<Users />} />
                    <Route path='users/new' element={<FormUser />} />
                    <Route path='users/edit/:id' element={<FormUser />} />
                    <Route path='*' element={<Guests />} />
                </Route>
            </Routes>
        </Router>
    )
}

export default RoutesProvider;
import React from 'react';
import { IAbout } from './';
import AboutView from './about.view';

const About: React.FC<IAbout> = () => {
    return (
        <AboutView></AboutView>
    );
};

export default About;
import React from "react";
import IncotermsWrapper from "./incoterms.style";
import { IIncotermsData, IIncotermsStages } from "./types";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import {
  ExtendedRefs,
  FloatingPortal,
  ReferenceType,
} from "@floating-ui/react";
import { IncotermsDropdown } from "./components";
import { Tooltip } from "@mui/material";

interface IProps {
  dropdownOpen: boolean;
  mobileDropdownOpen: boolean;
  incotermsOptions: string[];
  currentIncoterm: IIncotermsData;
  incotermsStages: IIncotermsStages[];
  listInteractionRefs: ExtendedRefs<ReferenceType | null>;
  floatingStyles: React.CSSProperties;
  getReferenceProps: (
    userProps?: React.HTMLProps<Element> | undefined
  ) => Record<string, unknown>;
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>;
  mobileFloatingRefs: ExtendedRefs<ReferenceType | null>;
  mobileFloatingStyles: React.CSSProperties;
  getMobileReferenceProps: (
    userProps?: React.HTMLProps<Element> | undefined
  ) => Record<string, unknown>;
  getMobileFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement> | undefined
  ) => Record<string, unknown>;
  onChangeIncoterm: (acronym: string) => void;
  onDropdownClick: () => void;
}

const IncotermsViewNoMemo: React.FC<IProps> = (props) => (
  <IncotermsWrapper className="incoterms-component">
    <div className="inconterms-informations">
      <div className="incoterms-container container">
        <>
          <div
            className="incoterm-dropdown mobile"
            ref={props.mobileFloatingRefs.setReference}
            {...props.getMobileReferenceProps()}
          >
            <div className="incoterm-dropdown__details">
              <div className="incoterm-dropdown__acronym">
                {props.currentIncoterm.acronym}
              </div>
              <div className="incoterm-dropdown__name">
                {props.currentIncoterm.name}
              </div>
            </div>
            {props.mobileDropdownOpen ? (
              <BiChevronUp className="incoterm-dropdown__icon" size={32} />
            ) : (
              <BiChevronDown className="incoterm-dropdown__icon" size={32} />
            )}
          </div>
          {props.mobileDropdownOpen && (
            <FloatingPortal>
              <IncotermsDropdown
                className="mobile"
                incotermsOptions={props.incotermsOptions}
                listInteractionRefs={props.mobileFloatingRefs}
                floatingStyles={props.mobileFloatingStyles}
                onChangeIncoterm={props.onChangeIncoterm}
                getFloatingProps={props.getMobileFloatingProps}
              />
            </FloatingPortal>
          )}
        </>

        <div className="stage-line grid-line">
          <div className="blank-cell"></div>
          <div className="stages-wrapper">
            <div className="stages">
              {props.incotermsStages?.map((incotermsStage, index) => (
                <Tooltip title={incotermsStage.name} key={index}>
                  <div key={incotermsStage.index} className="stage">
                    <img
                      className="stage__icon"
                      src={incotermsStage.icon}
                      alt={incotermsStage.name}
                    />
                    <div className="stage__div__name">
                      <span className="stage__name">{incotermsStage.name}</span>
                    </div>
                  </div>
                </Tooltip>
              ))}
              <div className="stage blank"></div>
            </div>
          </div>
        </div>

        <div className="incoterm-line grid-line">
          <div
            className="incoterm-dropdown desktop"
            ref={props.listInteractionRefs.setReference}
            {...props.getReferenceProps()}
          >
            <div className="incoterm-dropdown__details">
              <div className="incoterm-dropdown__acronym">
                {props.currentIncoterm.acronym}
              </div>
              <div className="incoterm-dropdown__name">
                {props.currentIncoterm.name}
              </div>
            </div>
            {props.dropdownOpen ? (
              <BiChevronUp className="incoterm-dropdown__icon" size={32} />
            ) : (
              <BiChevronDown className="incoterm-dropdown__icon" size={32} />
            )}
          </div>

          {props.dropdownOpen && (
            <FloatingPortal>
              <IncotermsDropdown
                incotermsOptions={props.incotermsOptions}
                listInteractionRefs={props.listInteractionRefs}
                floatingStyles={props.floatingStyles}
                onChangeIncoterm={props.onChangeIncoterm}
                getFloatingProps={props.getFloatingProps}
              />
            </FloatingPortal>
          )}

          <div className="incoterm-stages container">
            <div className="bar-captions mobile">
              <span>Fábrica</span>
              <span>Cliente</span>
            </div>
            <div className="incoterm-stage incoterm-stage__cost">
              <div
                className={`factory-bar size__${props.currentIncoterm.costStage}`}
              >
                <span>Fábrica</span>
              </div>
              <div className="customer-bar">
                <span>Cliente</span>
              </div>
            </div>
            <div className="incoterm-stage incoterm-stage__risk">
              <div
                className={`factory-bar size__${props.currentIncoterm.riskStage}`}
              >
                <span>Fábrica</span>
              </div>
              <div className="customer-bar">
                <span>Cliente</span>
              </div>
            </div>
            <div className="incoterm-stage incoterm-stage__safety">
              <div
                className={`factory-bar size__${props.currentIncoterm.safetyStage}`}
              >
                <span>Fábrica</span>
              </div>
              <div className="customer-bar">
                <span>Cliente</span>
              </div>
            </div>
          </div>
        </div>

        <div className="detail-line grid-line container">
          <div className="captions">
            <div className="captions__cost">
              <div className="square"></div>
              Custos
            </div>
            <div className="captions__risk">
              <div className="square"></div>
              Riscos
            </div>
            <div className="captions__safety">
              <div className="square"></div>
              Seguro
            </div>
          </div>

          <div className="first-column">
            <span className="detail freight-details">
              <strong>Frete:&nbsp;</strong>
              <span>{props.currentIncoterm.freightDetails}</span>
            </span>
            <span className="detail risk-details">
              <strong>Modal:&nbsp;</strong>
              <span>{props.currentIncoterm.riskDetails}</span>
            </span>
          </div>
          <div className="second-column">
            <span className="detail more-details">
              <strong>Mais detalhes:&nbsp;</strong>
              <span>{props.currentIncoterm.moreDetails}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </IncotermsWrapper>
);

const IncotermsView = React.memo(IncotermsViewNoMemo);
export default IncotermsView;

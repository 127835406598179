import { styled } from "styled-components";

const UsersWrapper = styled.div`
  flex-grow: 1;
  padding-top: 2rem;
  max-height: 100vh;
  overflow-y: auto;

  .page-title {
    margin-bottom: 2rem;
  }

  .user-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;

    .cta-create-new {
      background-color: #DADADA;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 120px;
    }
  }
`

export default UsersWrapper;
import React, { useCallback } from 'react';
import DashboardHeaderView from './dashboard-header.view';
import IDashboardHeader from './dashboard-header.props';
import { useRenderDashboardNavbar } from '../../Hooks';
import { dashboardRoutes } from '../../Mocks';
import { authService } from '../../Services';
import { useNavigate } from 'react-router-dom';

const DashboardHeader: React.FC<IDashboardHeader> = () => {
  const renderDashboardNavbar = useRenderDashboardNavbar({ routes: dashboardRoutes });
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    authService.logout()
    .then(() => {
      navigate("/")
    });
  }, [navigate]);

  return <DashboardHeaderView
    renderDashboardNavbar={renderDashboardNavbar}
    handleLogout={handleLogout}
  />;
};

export default DashboardHeader;

import { styled } from "styled-components";

const AboutServiceCardWrapper = styled.div`
  &.about-service-card-component {
    @media (max-width: 720px) {
      padding: 1rem;
    }

    .about-service-card-wrapper-left {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 64px;

      @media (max-width: 720px) {
        flex-direction: column;
        gap: 30px;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
      }
    }

    .about-service-card-wrapper-right {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 64px;

      @media (max-width: 720px) {
        flex-direction: column;
        gap: 30px;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
      }
    }

    .informations-container {
      display: flex;
      padding: 32px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      flex: 1 0 0;

      @media (max-width: 720px) {
        padding: 1rem;
      }

      .title {
        color: #2f2f2f;
        text-align: justify;
        font-family: Roboto, Lexend;
        font-size: 36px;
        font-weight: 500;
        line-height: normal;
        margin: 0px;
        margin-bottom: 15px;

        @media (max-width: 720px) {
          text-align: center;
        }
      }

      .description {
        color: #5e5e5e;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 720px) {
          text-align: center;
        }
      }
    }

    .image {
      width: 281px;
      height: 307px;
      border-radius: 8px;
      background: #cbcbcb;
      position: relative;

      img {
        width: 281px;
        height: 307px;
        border-radius: 8px;
        object-fit: cover;
      }

      @media (max-width: 720px) {
        display: none;
      }
    }

    .image::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(28, 81, 230, 0.4);
      border-radius: 8px;
      z-index: 1;
    }
  }
`;

export default AboutServiceCardWrapper;

import React from 'react';
import AboutServiceCardWrapper from './about-service-card.style';

interface IProps {
  imagePosition: string;
  description: string;
  title: string;
  imageUrl: string;
}

const AboutServiceCardViewNoMemo: React.FC<IProps> = (props) => (
  <AboutServiceCardWrapper className='about-service-card-component'>
    {
      props.imagePosition === 'left' &&
      <div className='about-service-card-wrapper-left'>
        <div className='image'>
          <img src={props.imageUrl} alt={props.title} />
        </div>
        <div className='informations-container'>
          <h1 className='title'>{props.title}</h1>
          <div className='description-container'>
            <span className='description'>{props.description}</span>
          </div>
        </div>
      </div>
    }

    {
      props.imagePosition === 'right' &&
      <div className='about-service-card-wrapper-right'>
        <div className='informations-container'>
          <h1 className='title'>{props.title}</h1>
          <div className='description-container'>
            <span className='description'>{props.description}</span>
          </div>
        </div>
        <div className='image'>
          <img src={props.imageUrl} alt={props.title} />
        </div>
      </div>
    }
  </AboutServiceCardWrapper>
);

const AboutServiceCardView = React.memo(AboutServiceCardViewNoMemo);
export default AboutServiceCardView;

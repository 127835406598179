interface IProps {
  number: number
};

const formatNumberToLocale = (props: IProps): string => {
  const { number } = props;

  return number.toLocaleString('pt');
}

export default formatNumberToLocale;
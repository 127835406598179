import { IMarker } from "../../../Types";

const AIRPORTS: IMarker[] = [
  { label: "Aeroporto de Goiânia", lat: -16.6331243, lng: -49.2261348, type: 'airport' },
  { label: "Aeroporto de Ponta Porã", lat: -22.5517912, lng: -55.7065131, type: 'airport' },
  { label: "Aeroporto de Campo Grande", lat: -20.4553894, lng: -54.6815659, type: 'airport' },
  { label: "Aeroporto de Tabatinga", lat: -4.2484826, lng: -69.9395278, type: 'airport' },
  { label: "Aeroporto de Corumbá", lat: -19.0144719, lng: -57.6625399, type: 'airport' },
  { label: "Aeroporto de Macapá", lat: 0.0446093, lng: -51.0698905, type: 'airport' },
  { label: "Aeroporto de Boa Vista", lat: 2.8412191, lng: -60.6922569, type: 'airport' },
  { label: "Aeroporto de Manaus", lat: -3.0300126, lng: -60.03821759999999, type: 'airport' },
  { label: "Aeroporto de Fortaleza", lat: -3.7785789, lng: -38.5407156, type: 'airport' },
  { label: "Aeroporto de São Luís", lat: -2.5825646, lng: -44.2393679, type: 'airport' },
  { label: "Aeroporto de Belém", lat: -1.3969238, lng: -48.4757011, type: 'airport' },
  { label: "Aeroporto de Teresina", lat: -5.0599379, lng: -42.8213461, type: 'airport' },
  { label: "Aeroporto de Várzea Grande", lat: -15.6512452, lng: -56.1219213, type: 'airport' },
  { label: "Aeroporto de Recife", lat: -8.133788299999999, lng: -34.917064, type: 'airport' },
  { label: "Aeroporto de Maceió", lat: -7.5355998, lng: -36.006475, type: 'airport' },
  { label: "Aeroporto de Brasília", lat: -15.8698978, lng: -47.9182851, type: 'airport' },
  { label: "Aeroporto de Salvador", lat: -12.9141661, lng: -38.3364555, type: 'airport' },
  { label: "Aeroporto de Bayeux", lat: -7.1460091, lng: -34.9487764, type: 'airport' },
  { label: "Aeroporto de São Gonçalo do Amarante", lat: -5.762602999999999, lng: -35.2891226, type: 'airport' },
  { label: "Aeroporto de Petrolina", lat: -9.5182297, lng: -43.0045702, type: 'airport' },
  { label: "Aeroporto de São Carlos", lat: -23.5557714, lng: -46.6395571, type: 'airport' },
  { label: "Aeroporto de Porto Seguro", lat: -16.4426393, lng: -39.0798962, type: 'airport' },
  { label: "Aeroporto de Goianá", lat: -21.5103736, lng: -43.1621591, type: 'airport' },
  { label: "Aeroporto de Aracaju", lat: -10.9875443, lng: -37.0702589, type: 'airport' },
  { label: "Aeroporto de Lagoa Santa", lat: -19.632283, lng: -43.961765, type: 'airport'  }
]

export default AIRPORTS;
import React, { useCallback, useState } from 'react';
import LoginFormView from './login-form.view';
import ILoginForm from './login-form.props';
import { usePostLogin } from '../../Hooks';

const LoginForm: React.FC<ILoginForm> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const postLogin = usePostLogin({ setDisplayError, setLoading });

  const onEmailChange = useCallback((value: string) => {
    setEmail(value);
    setDisplayError(false);
  }, [setEmail]);

  const onPasswordChange = useCallback((value: string) => {
    setPassword(value);
    setDisplayError(false);
  }, [setPassword]);

  const handleSubmit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    postLogin({ email, password });
  }, [email, password, postLogin]);

  return <LoginFormView
    email={email}
    password={password}
    displayError={displayError}
    onEmailChange={onEmailChange}
    onPasswordChange={onPasswordChange}
    handleSubmit={handleSubmit}
    loading={loading}
  />;
};

export default LoginForm;

import React from 'react';
import TaxUnitWrapper from './tax-unit.style';

interface IProps {
  name: string;
  symbol: string;
  formattedValue: string;
  formattedFullName: string;
  variation: number;
}

const TaxUnitViewNoMemo: React.FC<IProps> = (props) => (
  <TaxUnitWrapper className='tax-unit-component'>
    <div className='currency-container'>
      <span className='currency'>{props.symbol}</span>
    </div>
    <div className='text'>
      <div className='name'>{props.formattedFullName}</div>
      <div className='value-container'>
        <span className='value'>{props.formattedValue}</span>
        <span className={`variation ${props.variation >= 0 ? 'success' : 'danger'}`}>{props.variation}%</span>
      </div>
    </div>
  </TaxUnitWrapper>
);

const TaxUnitView = React.memo(TaxUnitViewNoMemo);
export default TaxUnitView;

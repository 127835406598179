import React, { useEffect, useState } from 'react';
import TaxCollectionView from './tax-collection.view';
import ITaxCollection from './tax-collection.props';
import { ITax } from '../../Types';
import { useGetTaxes } from '../../Hooks';

const TaxCollection: React.FC<ITaxCollection> = () => {
  const getTaxes = useGetTaxes();

  const [taxes, setTaxes] = useState<ITax[]>();

  useEffect(() => {
    getTaxes().then(taxes => {
      setTaxes(taxes);
    });
  }, [getTaxes]);

  return (
    <TaxCollectionView taxes={taxes} />
  );
};

export default TaxCollection;

import React from "react";
import AdminContainerCardWrapper from "./admin-container-card.style";
import { IContainer } from "../../Types";
import { Box, Tooltip } from "@mui/material";
import { ReactComponent as PackageOpen } from "../../Assets/images/icons/Vector.svg";
import { ReactComponent as PackageClosed } from "../../Assets/images/icons/Vector-1.svg";
import { ReactComponent as Scale } from "../../Assets/images/icons/Vector-2.svg";
import { ReactComponent as Weight } from "../../Assets/images/icons/Vector-3.svg";
import { BiCheck, BiLeftArrowAlt, BiPencil, BiTrash } from "react-icons/bi";

interface IProps {
  container: IContainer;
  confirmDelete: boolean;
  handleDelete: () => void;
  toggleConfirmDelete: () => void;
  handleEditRedirect: () => void;
}

const AdminContainerCardViewNoMemo: React.FC<IProps> = (props) => (
  <AdminContainerCardWrapper className="admin-container-card-component">
    <div className="img-container">
      <img
        src={props.container.imageUrl}
        alt={`Foto do ${props.container.name}`}
      />
    </div>
    <div className="content">
      <div className="name">
        <strong>{props.container.name}</strong>
        {props.confirmDelete ? (
          <div className="cta">
            <Tooltip placement="left" title="Confirmar deleção">
              <Box>
                <BiCheck
                  className="clickable"
                  color="#DE2C2C"
                  onClick={props.handleDelete}
                />
              </Box>
            </Tooltip>
            <Tooltip placement="left" title="Cancelar">
              <Box>
                <BiLeftArrowAlt
                  className="clickable"
                  onClick={props.toggleConfirmDelete}
                />
              </Box>
            </Tooltip>
          </div>
        ) : (
          <div className="cta">
            <Tooltip placement="left" title="Deletar">
              <Box>
                <BiTrash
                  className="clickable"
                  color="#DE2C2C"
                  onClick={props.toggleConfirmDelete}
                />
              </Box>
            </Tooltip>
            <Tooltip placement="left" title="Editar">
              <Box>
                <BiPencil
                  className="clickable"
                  onClick={props.handleEditRedirect}
                />
              </Box>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="info-container">
        <Tooltip title="Volume interno" placement="top">
          <span className="info txt-gray-1">
            <PackageOpen className="info-icon" />
            {props.container.innerSize} m³
          </span>
        </Tooltip>
        <Tooltip title="Altura" placement="top">
          <span className="info txt-gray-1">
            <PackageClosed className="info-icon" />
            {props.container.height} m
          </span>
        </Tooltip>
        <Tooltip title="Largura" placement="top">
          <span className="info txt-gray-1">
            <PackageClosed className="info-icon" />
            {props.container.width} m
          </span>
        </Tooltip>
        <Tooltip title="Profundidade" placement="top">
          <span className="info txt-gray-1">
            <PackageClosed className="info-icon" />
            {props.container.depth} m
          </span>
        </Tooltip>
        <Tooltip title="Peso máximo" placement="bottom">
          <span className="info txt-gray-1">
            <Scale className="info-icon" />
            {props.container.weightCapacity} kg
          </span>
        </Tooltip>
        <Tooltip title="Cubagem máxima" placement="bottom">
          <span className="info txt-gray-1">
            <Weight className="info-icon" />
            {props.container.cubage} kg/m³
          </span>
        </Tooltip>
      </div>
    </div>
  </AdminContainerCardWrapper>
);

const AdminContainerCardView = React.memo(AdminContainerCardViewNoMemo);
export default AdminContainerCardView;

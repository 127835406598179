import { IUser } from "../Types";
import Service from "./Service";
import { ILoginBody } from "./Types";

const authService = {
  login: async (body: ILoginBody): Promise<IUser | undefined> => {
    const service = Service;
    const { data: user } = (await service.post("User/login", body)) as {
      data: IUser;
    };
    service.setBearerToken(user.token);
    localStorage.setItem("token", user.token);
    if (user === undefined) return Promise.reject();
    return Promise.resolve(user);
  },

  logout: () => {
    localStorage.removeItem("token");
    Service.setBearerToken("");
    return Promise.resolve();
  },
};

export default authService;

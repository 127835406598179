import { styled } from "styled-components";
import { BREAKPOINTS } from "../../../../Constants";

const IncotermsDropdownWrapper = styled.div`
  &.incoterms-dropdown-component{
    background-color: white;
    border-radius: .35rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px;
    display: flex;
    flex-direction: column;

    &.mobile {
      display: flex;
      @media (min-width: 1400px) {
        display: none;
      }
    }
    
    .incoterms-dropdown__option {
      transition: .2s;
      padding: 1rem;
      cursor: pointer;

      &:not(:last-of-type) {
        border-bottom: 1px solid #d9d9d9;
      }

      &:hover {
        background-color: #EFEFEF;
        transition: .2s;
      }
    }
  }
`;

export default IncotermsDropdownWrapper;

import { useCallback, useMemo } from 'react';
import { BREAKPOINTS } from '../Constants';

const useGetBreakpoint = () => {
  const breakpointsValues = useMemo<number[]>(() => Object.values(BREAKPOINTS), []);
  const breakpointsKeys = useMemo<string[]>(() => Object.keys(BREAKPOINTS), []);

  const getBreakpoint = useCallback((): string => {
    let index = 0;
    for (; index < breakpointsValues.length && breakpointsValues[index] <= window.innerWidth; ++index);
    return breakpointsKeys[Math.max(index - 1, 0)]
  }, [breakpointsValues, breakpointsKeys]);

  return getBreakpoint;
}

export { useGetBreakpoint };

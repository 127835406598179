import React, { useCallback, useMemo } from 'react';
import CustomTableView from './custom-table.view';
import ICustomTable from './custom-table.props';
import { IHeadCell } from '../Types';
import { formatPhone } from '../../../Library';
import ROWS_PER_PAGE from '../Constants/ROWS_PER_PAGE';

const CustomTable = <T extends unknown>(props: ICustomTable<T>): JSX.Element => {
  const { rows, total, headCells, onPageChange, onRowsPerPageChange } = props;
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(ROWS_PER_PAGE[0]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
    onPageChange(newPage+1);
  }, [onPageChange]);

  const handleRowsPerPageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    onRowsPerPageChange(newRowsPerPage)
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    onPageChange(1);
  }, [setRowsPerPage, setPage, onPageChange, onRowsPerPageChange]);

  const renderCellValue = useCallback((row: T, headCell: IHeadCell<unknown>): JSX.Element => {
    const { phone, id } = headCell;
    const value = row[id];
    if (phone) return formatPhone(value) as never;
    return value;
  }, []);

  const emptyRows = useMemo(() => Math.max(0, rowsPerPage - rows.length), [rowsPerPage, rows]);

  return <CustomTableView
    emptyRows={emptyRows}
    total={total}
    handleChangePage={handleChangePage}
    handleRowsPerPageChange={handleRowsPerPageChange}
    rowsPerPage={rowsPerPage}
    rows={rows}
    headCells={headCells as IHeadCell<unknown>[]}
    page={page}
    renderCellValue={renderCellValue as (row: unknown, headCell: IHeadCell<unknown>) => JSX.Element}
  />;
}
export default CustomTable;
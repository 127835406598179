import { useCallback, useEffect, useState } from "react";
import { CustomButton, FileField, InputField } from "../../Components";
import { IContainer } from "../../Types";
import FormUserWrapper from "./style";
import { containerService } from "../../Services";
import { BiImageAlt, BiUserCircle } from "react-icons/bi";
import { useLocation, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import { ReactComponent as PackageOpen } from "../../Assets/images/icons/Vector.svg";
import { ReactComponent as PackageClosed } from "../../Assets/images/icons/Vector-1.svg";
import { ReactComponent as Scale } from "../../Assets/images/icons/Vector-2.svg";
import { ReactComponent as Weight } from "../../Assets/images/icons/Vector-3.svg";

const FormUser: React.FC = () => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);

  const [innerSize, setInnerSize] = useState<string>("");
  const [innerSizeError, setInnerSizeError] = useState<boolean>(false);
  
  const [height, setHeight] = useState<string>("");
  const [heightError, setHeightError] = useState<boolean>(false);
  
  const [width, setWidth] = useState<string>("");
  const [widthError, setWidthError] = useState<boolean>(false);
  
  const [depth, setDepth] = useState<string>("");
  const [depthError, setDepthError] = useState<boolean>(false);
  
  const [weightCapacity, setWeightCapacity] = useState<string>("");
  const [weightCapacityError, setWeightCapacityError] = useState<boolean>(false);
  
  const [cubage, setCubage] = useState<string>("");
  const [cubageError, setCubageError] = useState<boolean>(false);
  
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageUrlError, setImageUrlError] = useState<boolean>(false);
  
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const mode = location.pathname;
    const { id } = params;
    if (mode.startsWith(`/dashboard/containers/edit/${id}`))
      setPageTitle("Editar contêiner");
    else if (mode.startsWith("/dashboard/containers/new"))
      return setPageTitle("Criar contêiner");
    else return;

    if (!id) return;
    containerService.get(id).then((container: IContainer | undefined) => {
      if (!container) return;
      setName(container.name);
      setInnerSize(container.innerSize.toString());
      setHeight(container.height.toString());
      setWidth(container.width.toString());
      setDepth(container.depth.toString());
      setWeightCapacity(container.weightCapacity.toString());
      setCubage(container.cubage.toString());
      setImageUrl(container.imageUrl);
    });
  }, [location.pathname, params]);

  const handleChangeName = useCallback(
    (value: string) => {
      setNameError(false);
      setName(value);
    },
    [setName]
  );

  const handleChangeImageUrl = useCallback(
    (value: string) => {
      setImageUrlError(false);
      setImageUrl(value);
    },
    [setImageUrl]
  );

  const handleChangeInnerSize = useCallback(
    (value: string) => {
      setInnerSizeError(false);
      setInnerSize(value);
    },
    [setInnerSize]
  );
  
  const handleChangeHeight = useCallback(
    (value: string) => {
      setHeightError(false);
      setHeight(value);
    },
    [setHeight]
  );
  
  const handleChangeWidth = useCallback(
    (value: string) => {
      setWidthError(false);
      setWidth(value);
    },
    [setWidth]
  );
  
  const handleChangeDepth = useCallback(
    (value: string) => {
      setDepthError(false);
      setDepth(value);
    },
    [setDepth]
  );

  const handleChangeWeightCapacity = useCallback(
    (value: string) => {
      setWeightCapacityError(false);
      setWeightCapacity(value);
    },
    [setWeightCapacity]
  );
  
  const handleChangeCubage = useCallback(
    (value: string) => {
      setCubageError(false);
      setCubage(value);
    },
    [setCubage]
  );

  const isValid = useCallback(() => {
    let isValid = true;
    if (name === "") {
      setNameError(true);
      isValid = false;
    }
    if (imageUrl === "") {
      setImageUrlError(true);
      isValid = false;
    }
    if (innerSize === "") {
      setInnerSizeError(true);
      isValid = false;
    }
    if (height === "") {
      setHeightError(true);
      isValid = false;
    }
    if (width === "") {
      setWidthError(true);
      isValid = false;
    }
    if (depth === "") {
      setDepthError(true);
      isValid = false;
    }
    if (weightCapacity === "") {
      setWeightCapacityError(true);
      isValid = false;
    }
    if (cubage === "") {
      setCubageError(true);
      isValid = false;
    }
    return isValid;
  }, [name, imageUrl, innerSize, height, width, depth, weightCapacity, cubage]);

  const handleEditUser = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      const body = 
        { 
          name, 
          imageUrl, 
          cubage: parseFloat(cubage), 
          enabled: true, 
          height: parseFloat(height), 
          innerSize: parseFloat(innerSize), 
          weightCapacity: parseFloat(weightCapacity), 
          width: parseFloat(width),
          depth: parseFloat(depth)
        };
      if (!isValid()) return;
      setLoading(true);

      const { id } = params;

      if (!id){
        containerService.post(body).then(() => setLoading(false));
      }
      else {
        containerService.put(id, body).then(() => setLoading(false))
      }
    },
    [name, imageUrl, cubage, height, innerSize, weightCapacity, width, isValid, params, depth]
  );

  return (
    <FormUserWrapper className="container">
      <h1 className="page-title">{pageTitle}</h1>

      <div className="form-wrapper">
        <form onSubmit={handleEditUser} className="row">
          <div className="col-12 col-md-6">
            {/* Container name */}
            <InputField
              id="container-name"
              value={name}
              label="Nome do contêiner"
              icon={<BiUserCircle />}
              onChange={handleChangeName}
              error={nameError}
            />
          </div>

          {/* Container image */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-image"
              value={imageUrl}
              label="Url da imagem"
              icon={<BiImageAlt />}
              onChange={handleChangeImageUrl}
              error={imageUrlError}
            />
          </div>

          {/* Inner size */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-inner-size"
              value={innerSize}
              type="number"
              label="Volume interno"
              icon={<PackageOpen className="info-icon" />}
              onChange={handleChangeInnerSize}
              error={innerSizeError}
            />
          </div>

          {/* Cubage */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-cubage"
              value={cubage}
              type="number"
              label="Cubagem máxima"
              icon={<Weight className="info-icon" />}
              onChange={handleChangeCubage}
              error={cubageError}
            />
          </div>

          {/* Weight capacity */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-weight-capacity"
              value={weightCapacity}              
              type="number"
              label="Peso máximo"
              icon={<Scale className="info-icon" />}
              onChange={handleChangeWeightCapacity}
              error={weightCapacityError}
            />
          </div>

          <div className="col-12 section-title">
            Dimensões externas
          </div>

          {/* Height size */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-height-size"
              value={height}
              type="number"
              label="Altura"
              icon={<PackageClosed className="info-icon" />}
              onChange={handleChangeHeight}
              error={heightError}
            />
          </div>

          {/* Width size */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-width-size"
              value={width}
              type="number"
              label="Largura"
              icon={<PackageClosed className="info-icon" />}
              onChange={handleChangeWidth}
              error={widthError}
            />
          </div>

          {/* Depth size */}
          <div className="col-12 col-md-6">
            <InputField
              id="container-depth-size"
              value={depth}
              type="number"
              label="Profundidade"
              icon={<PackageClosed className="info-icon" />}
              onChange={handleChangeDepth}
              error={depthError}
            />
          </div>

          <div className="col-12">
            <CustomButton
              className="btn-container-form-send col-2"
              type="submit"
              onClick={handleEditUser}
              loading={loading}
            >
              Enviar
            </CustomButton>
          </div>

          {imageUrlError && nameError && (
            <div className="col-12">
              <Alert severity="error">Campos precisam estar preenchidos</Alert>
            </div>
          )}
        </form>
      </div>
    </FormUserWrapper>
  );
};

export default FormUser;

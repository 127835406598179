import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: '#1C51E6' },
    secondary: { main: '#EFEFEF' },
  },
  typography: {
    fontFamily: [
      'Lexend',
      'Sora',
      'sans-serif'
    ].join(',')
  }
});

const MuiConfig = {
  theme
}

export default MuiConfig;
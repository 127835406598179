import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
} from "axios";

class Service {
  constructor() {
    this.instance.interceptors.response.use(
      (response: AxiosResponse) => response,
    );
  }

  public baseURL: string = "https://mid4u-back.azurewebsites.net/";
  private token: string = localStorage.getItem('token') ?? '';
  private instance = axios.create({
    baseURL: this.baseURL,
    headers: {
      'Authorization': this.token ? `Bearer ${this.token}` : undefined,
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
    },
  });

  public setBearerToken(token: string): void {
    this.token = token;
    this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  public async get<T>(url: string, params: { fullUrl?: boolean, ignoreUnauthorized?: boolean } = { fullUrl: false, ignoreUnauthorized: false }): Promise<T> {
    
    try {
      const response: AxiosResponse<T> = params.fullUrl
        ? await axios.get(url)
        : await this.instance.get(url, { params });

      return response.data;
    } catch (error) {
      if (!(error instanceof AxiosError)) throw error;
      if (!(error.response && error.response.status === 401)) throw error;
      if (params.ignoreUnauthorized) throw error;
      localStorage.removeItem('token');
      window.location.href = '/admin';
      throw error;
    }
  }

  public async post<T>(
    url: string,
    data: object = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.instance.post(
        url,
        data,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async put<T>(
    url: string,
    data: object = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.instance.put(
        url,
        data,
        config
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async delete<T>(
    url: string,
    data: object = {},
    config: AxiosRequestConfig = {}
  ): Promise<T> {
    try {
      const response: AxiosResponse<T> = await this.instance.delete(
        url,
        data,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}


// Create a singleton instance
const service = new Service();

export default service;

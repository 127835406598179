import { styled, css } from "styled-components";

const LoginFormWrapper = styled.div`${() => css`
  &.login-form-component{
    background-color: white;
    max-width: min(400px, 100%);
    width: 100%;
    margin-inline: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    --half-height: calc(calc(100vh / 2) - 180px);
    margin-top: min(var(--half-height), 300px);
    padding: 2rem;

    .login-form-component__title {
      text-align: center;
      width: 100%;
      margin-bottom: 3rem;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .login-form-component__btn-submit {
        width: 100%;
      }
    }

    .error-alert {
      margin-top: 1rem;
    }
  }
`}`;

export default LoginFormWrapper;

import React, { useCallback, useState } from 'react';
import UserCardView from './user-card.view';
import IUserCard from './user-card.props';
import { userService } from '../../Services';
import { useNavigate } from 'react-router-dom';

const UserCard: React.FC<IUserCard> = (props) => {
  const { user, onDelete } = props;

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    userService.delete(user.id).then(onDelete);
  }, [user.id]);

  const toggleConfirmDelete = useCallback(() => {
    setConfirmDelete(current => !current);
  }, [])

  const handleEditRedirect = useCallback(() => {
    navigate(`edit/${user.id}`);
  }, [navigate, user.id]);

  return <UserCardView 
    user={user}
    confirmDelete={confirmDelete}
    handleDelete={handleDelete}
    toggleConfirmDelete={toggleConfirmDelete}
    handleEditRedirect={handleEditRedirect}
  />;
};

export default UserCard;

import { useCallback } from 'react';
import { authService } from '../Services';
import { useNavigate } from 'react-router-dom';

interface IProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayError: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IPostLoginBody {
  email: string;
  password: string;
}

const usePostLogin = (props: IProps) => {
  const {
    setLoading,
    setDisplayError,
  } = props;

  const navigate = useNavigate();

  const postLogin = useCallback((body: IPostLoginBody) => {
    setLoading(true);

    authService.login(body)
    .then(() => {
      navigate('/dashboard')
    })
    .catch(() => setDisplayError(true))
    .finally(() => setLoading(false))
  }, [setLoading, setDisplayError, navigate]);

  return postLogin;
}

export { usePostLogin };

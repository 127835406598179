const SHIP_SVG = `m 22.160948,32.739161
c 1.934596,-1.095515 4.190364,-1.163985 6.165114,-0.205376
l 0.391137,0.205376 4.871473,2.75837
c 0.905103,0.512558 1.272148,1.758948 0.819481,2.784103 -0.420025,0.951928 -1.399426,1.378154 -2.26195,1.023787
l -0.196435,-0.09572 -4.871358,-2.75837
c -0.928676,-0.525774 -2.005197,-0.578312 -2.963453,-0.157745
l -0.314874,0.157745 -2.412858,1.366318
c -1.934597,1.095522 -4.190306,1.163992 -6.165057,0.205442
L 14.831032,37.817649 12.418115,36.451331
C 11.489498,35.925557 10.412919,35.873019 9.4547204,36.293586
l -0.314874,0.157745 -4.8714739,2.75837
C 3.3631537,39.722258 2.2624234,39.306791 1.8098145,38.281667 1.3897898,37.329738 1.6757759,36.18707 2.4437215,35.618284
L 2.6291796,35.497571 7.5005957,32.7392
c 1.9346547,-1.095515 4.1903633,-1.163984 6.1651133,-0.205376
l 0.391137,0.205376 2.412858,1.366319
c 0.928676,0.525774 2.005198,0.578312 2.963454,0.157745
l 0.314874,-0.157745
z

M 19.941579,0
c 1.012102,0 1.832507,0.929113 1.832507,2.075217
V 4.81354
l 6.100002,1.151324
c 1.76728,0.333548 3.062479,2.0650627 3.062479,4.093957
v 7.122274
l 3.772189,1.424006
c 1.271224,0.479975 1.908944,2.087871 1.380996,3.482827
l -3.973939,10.500574 -3.39873,-1.924551
c -2.063608,-1.168552 -4.492585,-1.168552 -6.556135,0
l -2.412858,1.366318
c -1.031862,0.584201 -2.246292,0.584201 -3.278154,0
l -2.412858,-1.366318
c -2.063551,-1.168552 -4.4925276,-1.168552 -6.5561357,0
L 5.1727241,31.982331 0.30281108,22.331147
C -0.41435082,20.90983 0.19592688,19.10087 1.5672749,18.583314
l 3.7142982,-1.402219
v -7.122274
c 0,-2.0288943 1.2952579,-3.760409 3.0624803,-4.093957
L 14.444055,4.81354
V 2.075217
C 14.444055,0.929113 15.264517,0 16.27662,0
Z

M 18.109128,8.3294661 8.9465884,10.058821
v 5.738872
l 8.0034576,-3.021172
c 0.752404,-0.28402 1.565703,-0.28402 2.317934,0
l 8.00363,3.021172
v -5.738872
z`;

export default SHIP_SVG;
const ports = [
  {
    port: "Porto de Santos",
    latitude: -23.9535,
    longitude: -46.335,
    state: "SP",
  },
  {
    port: "Porto de Paranaguá",
    latitude: -25.5288,
    longitude: -48.5126,
    state: "PR",
  },
  {
    port: "Porto de Rio Grande",
    latitude: -32.0328,
    longitude: -52.1051,
    state: "RS",
  },
  {
    port: "Porto de Itajaí",
    latitude: -26.9144,
    longitude: -48.6663,
    state: "SC",
  },
  {
    port: "Porto de São Francisco do Sul",
    latitude: -26.2415,
    longitude: -48.6095,
    state: "SC",
  },
  {
    port: "Porto de Imbituba",
    latitude: -28.2306,
    longitude: -48.6746,
    state: "SC",
  },
  {
    port: "Porto de Itapoá",
    latitude: -26.1222,
    longitude: -48.6144,
    state: "SC",
  },
  {
    port: "Porto de Navegantes",
    latitude: -26.9004,
    longitude: -48.6524,
    state: "SC",
  },
  {
    port: "Porto de Laguna",
    latitude: -28.4877,
    longitude: -48.7754,
    state: "SC",
  },
  {
    port: "Porto de Antonina",
    latitude: -25.4195,
    longitude: -48.7123,
    state: "PR",
  },
  {
    port: "Porto de Sepetiba",
    latitude: -22.9752,
    longitude: -43.7476,
    state: "RJ",
  },
  {
    port: "Porto do Rio de Janeiro",
    latitude: -22.9009,
    longitude: -43.1729,
    state: "RJ",
  },
  {
    port: "Porto de Vitória",
    latitude: -20.326,
    longitude: -40.3303,
    state: "ES",
  },
  {
    port: "Porto de Angra dos Reis",
    latitude: -23.0052,
    longitude: -44.3196,
    state: "RJ",
  },
  {
    port: "Porto de São Sebastião",
    latitude: -23.8298,
    longitude: -45.413,
    state: "SP",
  },
  {
    port: "Porto de Itaguaí",
    latitude: -22.8726,
    longitude: -43.6949,
    state: "RJ",
  },
  {
    port: "Porto de Aratu",
    latitude: -12.8537,
    longitude: -38.5223,
    state: "BA",
  },
  {
    port: "Porto de Suape",
    latitude: -8.3926,
    longitude: -34.995,
    state: "PE",
  },
  {
    port: "Porto de Salvador",
    latitude: -12.9822,
    longitude: -38.505,
    state: "BA",
  },
  {
    port: "Porto de Maceió",
    latitude: -9.6546,
    longitude: -35.7134,
    state: "AL",
  },
  {
    port: "Porto de Recife",
    latitude: -8.0652,
    longitude: -34.871,
    state: "PE",
  },
  {
    port: "Porto de Cabedelo",
    latitude: -6.9798,
    longitude: -34.8384,
    state: "PB",
  },
  {
    port: "Porto de Fortaleza",
    latitude: -3.7235,
    longitude: -38.541,
    state: "CE",
  },
];

export default ports;

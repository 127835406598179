import { styled, css } from "styled-components";

const ProductWrapper = styled.div`${() => css`
  &.product-component{
    display: flex;
    background-color: white;
    padding: .75rem 2rem;
    align-items:center;
    gap: 1rem;

    .product-name {
      flex-grow: 1;
      font-weight: bold;
    }

    .divider {
      align-self: stretch;
    }

    button.btn-delete {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: .5rem;
      cursor: pointer;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }
  }
`}`;

export default ProductWrapper;

import React from "react";
import UserCardWrapper from "./user-card.style";
import { IUser } from "../../Types";
import {
  BiCrown,
  BiTrash,
  BiPencil,
  BiCheck,
  BiLeftArrowAlt,
} from "react-icons/bi";
import { Box, Tooltip } from "@mui/material";

interface IProps {
  user: IUser;
  confirmDelete: boolean;
  handleDelete: () => void;
  toggleConfirmDelete: () => void;
  handleEditRedirect: () => void;
}

const UserCardViewNoMemo: React.FC<IProps> = (props) => (
  <UserCardWrapper className="user-card-component">
    <div className="user-card__admin-icon">
      {props.user.administrator && (
        <Tooltip title="Administrador" placement="left">
          <Box className="icon-wrapper">
            <BiCrown
              color="white"
              className="user-card__icon stroke"
              size={24}
            />
            <BiCrown color="#EB5406" className="user-card__icon" size={24} />
          </Box>
        </Tooltip>
      )}
    </div>
    <div className="content">
      <span className="user-card__name">{props.user.name}</span>
      <span className="user-card__email">{props.user.email}</span>
    </div>
    {props.confirmDelete ? (
      <div className="cta">
        <Tooltip placement="left" title="Confirmar deleção">
          <Box>
            <BiCheck
              className="clickable"
              color="#DE2C2C"
              onClick={props.handleDelete}
            />
          </Box>
        </Tooltip>
        <Tooltip placement="left" title="Cancelar">
          <Box>
            <BiLeftArrowAlt
              className="clickable"
              onClick={props.toggleConfirmDelete}
            />
          </Box>
        </Tooltip>
      </div>
    ) : (
      <div className="cta">
        <Tooltip placement="left" title="Deletar">
          <Box>
            <BiTrash
              className="clickable"
              color="#DE2C2C"
              onClick={props.toggleConfirmDelete}
            />
          </Box>
        </Tooltip>
        <Tooltip placement="left" title="Editar">
          <Box>
            <BiPencil
              className="clickable"
              onClick={props.handleEditRedirect}
            />
          </Box>
        </Tooltip>
      </div>
    )}
  </UserCardWrapper>
);

const UserCardView = React.memo(UserCardViewNoMemo);
export default UserCardView;

import React, { useMemo } from 'react';
import CustomButtonView from './custom-button.view';
import ICustomButton from './custom-button.props';

const CustomButton: React.FC<ICustomButton> = (props) => {
  const {
    children,
    color = "primary",
    loading,
    className,
    type = "button",
    onClick,
  } = props;

  const progressColor = useMemo<"inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning">(() => {
    if (color === "primary") return "secondary"
    return "primary";
  }, [color]);

  return <CustomButtonView
    className={className}
    children={children}
    color={color}
    loading={loading}
    onClick={onClick}
    type={type}
    progressColor={progressColor}
  />;
};

export default CustomButton;

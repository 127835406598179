import React from "react";
import LoginWrapper from "./style";
import { LoginForm } from "../../Components";

const Login: React.FC = () => {
  return (
    <LoginWrapper>
      <LoginForm />
    </LoginWrapper>
  )
}

export default Login
import React, { useMemo } from "react";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import { styled, css } from "styled-components"

interface IProps {
  routes: {
    icon: IconType;
    name: string;
    path: string;
  }[];
}

const NavBarWrapper = styled.div`${() => css`
  display: flex;
  flex-direction: column;
  gap: .5rem;
`}`;

const useRenderDashboardNavbar = (props: IProps): React.ReactNode => {
  const renderDashboardNavbar = useMemo<React.ReactNode>(() => {
    return (
      <NavBarWrapper className="navbar">
        {props.routes.map((route, index) => (
          <nav key={`nav-${index}`}>
            <NavLink
              to={route.path}
              className={"txt-white"}
            >
              <span className="nav-icon">{route.icon({ size: 20 })}</span>
              <span className="nav-name">{route.name}</span>
            </NavLink>
          </nav>
        ))}
      </NavBarWrapper>
    );
  }, [props.routes]);

  return renderDashboardNavbar;
};

export { useRenderDashboardNavbar };

import styled, { css } from "styled-components";

const MarkerWrapper = styled.div`${() => css`
  &.marker-component{
    &.map-marker-label {
      text-shadow: -1px -1px 0px #FFF,
               -1px 1px 0px #FFF,
                1px -1px 0px #FFF,
                1px 0px 0px #FFF;
    }
  }
`}`;

export default MarkerWrapper;

//import axios from "axios";

import { IUser } from "../Types";
import Service from "./Service";
import IPagination from "./Types/IPagination";

const userService = {
  getAll: async (page: number): Promise<IPagination<IUser>> => {
    return (await Service.get(
      `User?PageNumber=${page}&PageSize=${10}`
    )) as IPagination<IUser>;
  },

  get: async (id: string): Promise<{ data: IUser }> => {
    return (await Service.get(`User/${id}`)) as { data: IUser };
  },

  post: async (body: Partial<IUser>): Promise<IUser | undefined> => {
    return (await Service.post(`User`, body)) as IUser;
  },

  put: async (id: string, body: Partial<IUser>): Promise<IUser | undefined> => {
    return (await Service.put(`User/${id}/full`, body)) as IUser;
  },

  delete: async (id: string): Promise<void> => {
    return await Service.delete(`User/${id}`);
  },
};

export default userService;

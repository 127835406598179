import { ITaxKeys } from "../Types";

interface IProps {
  key: ITaxKeys;
};

const formatCurrencySymbol = (props: IProps) => {
  const { key } = props;

  switch (key) {
    case 'USDBRL':
      return '$';
    case 'CNYBRL':
      return '¥';
    case 'EURBRL':
      return '€';
    case 'GBPBRL':
      return '£';
    case 'ARSBRL':
      return '$';
    default:
      return 'R$';
  };
}

export default formatCurrencySymbol;

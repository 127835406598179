import { IMarker } from "../Types";

const brazilStates: IMarker[] = [
  {
    label: "Acre",
    lat: -9.974,
    lng: -67.8076,
    type: 'state',
  },
  {
    label: "Alagoas",
    lat: -9.6663,
    lng: -35.7351,
    type: 'state',
  },
  {
    label: "Amapá",
    lat: 0.0349,
    lng: -51.0668,
    type: 'state',
  },
  {
    label: "Amazonas",
    lat: -3.119,
    lng: -60.0212,
    type: 'state',
  },
  {
    label: "Bahia",
    lat: -12.9718,
    lng: -38.5011,
    type: 'state',
  },
  {
    label: "Ceará",
    lat: -3.7172,
    lng: -38.5433,
    type: 'state',
  },
  {
    label: "Distrito Federal",
    lat: -15.7934,
    lng: -47.8825,
    type: 'state',
  },
  {
    label: "Espírito Santo",
    lat: -20.3155,
    lng: -40.3128,
    type: 'state',
  },
  {
    label: "Goiás",
    lat: -16.686,
    lng: -49.2643,
    type: 'state',
  },
  {
    label: "Maranhão",
    lat: -2.5395,
    lng: -44.282,
    type: 'state',
  },
  {
    label: "Mato Grosso",
    lat: -15.601,
    lng: -56.0974,
    type: 'state',
  },
  {
    label: "Mato Grosso do Sul",
    lat: -20.4428,
    lng: -54.6464,
    type: 'state',
  },
  {
    label: "Minas Gerais",
    lat: -19.9167,
    lng: -43.9345,
    type: 'state',
  },
  {
    label: "Pará",
    lat: -1.4556,
    lng: -48.5044,
    type: 'state',
  },
  {
    label: "Paraíba",
    lat: -7.11532,
    lng: -34.861,
    type: 'state',
  },
  {
    label: "Paraná",
    lat: -25.4296,
    lng: -49.2719,
    type: 'state',
  },
  {
    label: "Pernambuco",
    lat: -8.0475,
    lng: -34.877,
    type: 'state',
  },
  {
    label: "Piauí",
    lat: -5.0919,
    lng: -42.8034,
    type: 'state',
  },
  {
    label: "Rio de Janeiro",
    lat: -22.9068,
    lng: -43.1729,
    type: 'state',
  },
  {
    label: "Rio Grande do Norte",
    lat: -5.7945,
    lng: -35.211,
    type: 'state',
  },
  {
    label: "Rio Grande do Sul",
    lat: -30.0346,
    lng: -51.2177,
    type: 'state',
  },
  {
    label: "Rondônia",
    lat: -8.7608,
    lng: -63.8999,
    type: 'state',
  },
  {
    label: "Roraima",
    lat: 2.8198,
    lng: -60.6717,
    type: 'state',
  },
  {
    label: "Santa Catarina",
    lat: -27.5954,
    lng: -48.548,
    type: 'state',
  },
  {
    label: "São Paulo",
    lat: -23.5505,
    lng: -46.6333,
    type: 'state',
  },
  {
    label: "Sergipe",
    lat: -10.9472,
    lng: -37.0731,
    type: 'state',
  },
  {
    label: "Tocantins",
    lat: -10.2486,
    lng: -48.3243,
    type: 'state',
  },
];

export default brazilStates;

import React from 'react';
import GuestFormWrapper from './guest-form.style';
import { CustomButton } from '../CustomButton';
import { Alert, Snackbar } from '@mui/material';

interface IProps {
  renderGuestFormFields: React.ReactNode;
  openSuccess: boolean;
  openFailure: boolean;
  setSuccessRequest: (value: boolean) => void;
  setFailureRequest: (value: boolean) => void;
  sendGuestForm: () => void;
}

const GuestFormViewNoMemo: React.FC<IProps> = (props) => (
  <GuestFormWrapper className='guest-form-component'>
    {props.renderGuestFormFields}
    <CustomButton
      className="btn-guest-form-send"
      onClick={props.sendGuestForm}
      color={props.openSuccess ? 'success' : 'primary'}
    >{props.openSuccess ? 'Enviado!' : 'Enviar'}</CustomButton>
    <Snackbar
      open={props.openSuccess}
      autoHideDuration={6000}
      onClose={() => props.setSuccessRequest(false)}
    >
      <Alert severity="success">Formulário enviado!</Alert>
    </Snackbar>
    <Snackbar
      open={props.openFailure}
      autoHideDuration={6000}
      onClose={() => props.setFailureRequest(false)}
    >
      <Alert severity="error">Ocorreu um erro!</Alert>
    </Snackbar>
  </GuestFormWrapper>
);

const GuestFormView = React.memo(GuestFormViewNoMemo);
export default GuestFormView;

import { IProduct } from "../components/Product"

interface IProps {
  products: Omit<IProduct, "onDelete">[];
}

interface IProductReturn {
  productWeight: number;
  productVolume: number;
}

const productSumFormula = (props: IProps): IProductReturn => {
  const {
    products
  } = props;

  return products.reduce((acc: IProductReturn, product: Omit<IProduct, "onDelete">) => {
    return ({
      productWeight: acc.productWeight + product.weight,
      productVolume: acc.productVolume + product.volume,
    })
  }, {
    productWeight: 0,
    productVolume: 0,
  })
}

export default productSumFormula;
import React from "react";
import VisualizerWrapper from "./visualizer.style";
import { IContainer } from "../../../../Types";
import { VISUALIZER_HEIGHT } from "../../../../Constants";

interface IProps {
  container?: IContainer;
  productWeight: number;
  productVolume: number;
  percentual: number;
}

const VisualizerViewNoMemo: React.FC<IProps> = (props) => (
  <VisualizerWrapper className={["visualizer-component", props.percentual > 1 && "overflow"].join(' ')}>
    <div className="title-items txt-white bg-primary container-details">
      <span className="bold-title">Capacidade máxima</span>
      <div className="splitted-values">
        <span className="weight">{props.container?.weightCapacity} kg</span>
        <div className="divider vertical bg-white"></div>
        <span className="weight">{props.container?.innerSize} m³</span>
      </div>
    </div>
    <div className="water">
      <div className="wave-animation">
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="#0E9DCB80"/>
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="#0E9DCB10"/>
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="#0E9DCB40"/>
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#0E9DCBFF"/>
            </g>
          </svg>
        </div>
        <div className="under-water" style={{ maxHeight: props.percentual * VISUALIZER_HEIGHT }}></div>
      </div>
      <div className="title-items txt-white product-details">
        <span className="bold-title">Produtos</span>
        <div className="splitted-values">
          <span className="weight">{props.productWeight} kg</span>
          <div className="divider vertical bg-white"></div>
          <span className="volume">{props.productVolume} m³</span>
        </div>
      </div>
    </div>
  </VisualizerWrapper>
);

const VisualizerView = React.memo(VisualizerViewNoMemo);
export default VisualizerView;

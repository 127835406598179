import React, { useCallback, useState } from "react";
import { Header, GuestForm, TaxCollection, ContainerCollection, CustomFooter, DrawerOptions, AboutServices, About, Incoterms, Brand, Map } from "../../Components";
import MainWrapper from "./style";
import { Drawer } from "@mui/material";

const Main: React.FC = () => {
    const [showDrawer, setShowDrawer] = useState(false);

    const handleOnClickShowDrawer = useCallback(() => setShowDrawer(true), []);
    const handleOnClickCloseDrawer = useCallback(() => setShowDrawer(false), []);

    return (
        <MainWrapper>
            <Header onClickShowDrawer={handleOnClickShowDrawer} />
            <Drawer onClose={handleOnClickCloseDrawer} open={showDrawer} children={<DrawerOptions />} />

            <div className="body-container" id="cover">
                <TaxCollection />
                <Brand />

                <section className="about bg-white" id="about">
                    <About />
                    <AboutServices />
                </section>


                <section className="bg-accent" id="iconterms">
                    <h1 className="txt-black container">INCOTERMS</h1>
                    <Incoterms />
                </section>

                <section className="containers bg-primary" id="containers">
                    <h1 className="txt-white container">Contêineres</h1>
                    <ContainerCollection />
                </section>


                <div className="slogan primary-to-accent">
                    <div className="slogan-text">
                        <h2>A MID4U lhe proporciona acompanhamento com profissionais especializados</h2>
                    </div>
                </div>

                <section className="bg-accent map-container">
                    <div className="container">
                        <Map />
                    </div>
                </section>

                <section className="guest-form bg-accent" id="contact">
                    <h1 className="txt-black container">Entre em contato</h1>
                    <div className="container">
                        <GuestForm />
                    </div>
                </section>
            </div>
            <CustomFooter />

        </MainWrapper >
    );
};

export default Main;

import React, { useCallback, useMemo, useState } from 'react';
import PhoneFieldView from './phone-field.view';
import IPhoneField from './phone-field.props';

const PhoneField: React.FC<IPhoneField> = (props) => {
  const {
    icon,
    id,
    label,
    type = 'phone',
    className,
    error,
    onChange: onChangeProps,
  } = props;

  const [phone, setPhone] = useState<string>("");

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const positionHelperClass = useMemo<string>(() => {
    const classes = [];
    if (isFocused) classes.push('active');
    if (isFilled) classes.push('filled');
    return classes.join(' ');
  }, [isFocused, isFilled]);

  const onBlur = useCallback(() => setIsFocused(false), []);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeProps(value);
    setIsFilled(value !== "");
    setPhone(value ?? "");
  }, [onChangeProps, setIsFilled, setPhone]);

  return <PhoneFieldView
    icon={icon} 
    id={id} 
    value={phone}
    label={label}
    type={type}
    onBlur={onBlur}
    onFocus={onFocus}
    positionHelperClass={positionHelperClass}
    onChange={onChange}
    className={className}
    error={error}
  />;
};

export default PhoneField;

import React, { useCallback, useEffect, useState } from "react";
import CalculatorView from "./calculator.view";
import ICalculator from "./calculator.props";
import { IContainer } from "../../Types";
import { containerService } from "../../Services";
import { useNavigate, useParams } from "react-router-dom";
import { IProduct } from "./components/Product";

const Calculator: React.FC<ICalculator> = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [container, setContainer] = useState<IContainer>();
  const [products, setProducts] = useState<Omit<IProduct, "onDelete">[]>([]);
  const [productName, setProductName] = useState<string>("");
  const [productNameError, setProductNameError] = useState<boolean>(false);
  const [productWeight, setProductWeight] = useState<string>("");
  const [productWeightError, setProductWeightError] = useState<boolean>(false);
  const [productVolume, setProductVolume] = useState<string>("");
  const [productVolumeError, setProductVolumeError] = useState<boolean>(false);

  useEffect(() => {
    if (id === undefined) {
      navigate("/404");
      return;
    }
    containerService.get(id).then((data: any) => {
      if (data === undefined) return navigate("/404");
      setContainer(data);
    });
  }, [id, container, navigate]);

  const validateForm = useCallback(() => {
    let valid = true;
    if (productName === "") { valid = false; setProductNameError(true) };
    if (productWeight === "") { valid = false; setProductWeightError(true) };
    if (productVolume === "") { valid = false; setProductVolumeError(true) };
    return valid;
  }, [productName, productVolume, productWeight]);

  const onProductDelete = useCallback((index: number) => {
    const auxProducts = [...products];
    auxProducts.splice(index, 1)
    setProducts(auxProducts);
  }, [products]);
  
  const onProductNameChange = useCallback((value: string) => {
    setProductNameError(false);
    setProductName(value);
  }, [setProductName]);

  
  const onProductWeightChange = useCallback((value: string) => {
    setProductWeightError(false);
    setProductWeight(value);
  }, [setProductWeight]);

  
  const onProductVolumeChange = useCallback((value: string) => {
    setProductVolumeError(false);
    setProductVolume(value);
  }, [setProductVolume]);

  const handleAddProduct = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      if (!validateForm()) return false;
      const newProduct: Omit<IProduct, "onDelete"> = {
        name: productName,
        weight: parseInt(productWeight),
        volume: parseInt(productVolume),
      };
      setProducts([...products, newProduct]);
      setProductName("");
      setProductWeight("");
      setProductVolume("");
      return false;
    },
    [products, validateForm, productName, productWeight, productVolume]
  );

  return (
    <CalculatorView
      container={container}
      handleAddProduct={handleAddProduct}
      onProductNameChange={onProductNameChange}
      productName={productName}
      productNameError={productNameError}
      onProductWeightChange={onProductWeightChange}
      productWeight={productWeight}
      productWeightError={productWeightError}
      onProductVolumeChange={onProductVolumeChange}
      productVolume={productVolume}
      productVolumeError={productVolumeError}
      products={products}
      onProductDelete={onProductDelete}
    />
  );
};

export default Calculator;

import React from 'react';
import LoginFormWrapper from './login-form.style';
import { InputField } from '../Fields';
import { BiMailSend } from 'react-icons/bi';
import { CustomButton } from '../CustomButton';
import { Alert } from '@mui/material';

interface IProps {
  onEmailChange: (value: string) => void;
  email: string;
  onPasswordChange: (value: string) => void;
  password: string;
  displayError: boolean;
  handleSubmit: (event: React.FormEvent) => void;
  loading: boolean;
}

const LoginFormViewNoMemo: React.FC<IProps> = (props) => (
  <LoginFormWrapper className='login-form-component'>
    <h3 className="login-form-component__title">Painel do administrador</h3>

    <form onSubmit={props.handleSubmit}>
      <InputField value={props.email} icon={<BiMailSend />} id="login-mail" label="E-mail" onChange={props.onEmailChange}></InputField>
      <InputField value={props.password} id="login-password" label="Senha" onChange={props.onPasswordChange} password></InputField>

      <CustomButton className='login-form-component__btn-submit' type="submit" onClick={props.handleSubmit} loading={props.loading}>
        Entrar
      </CustomButton>
    </form>

    { props.displayError &&
      <Alert severity="error" className="error-alert">Credenciais inválidas!</Alert>
    }
  </LoginFormWrapper>
);

const LoginFormView = React.memo(LoginFormViewNoMemo);
export default LoginFormView;

import React from 'react';
import MapWrapper from './map.style';
import { IMarker } from '../../Types';
import { GoogleMap } from '@react-google-maps/api';
import { Marker } from './components';
import { brazilStates } from '../../Mocks';
import { Dropdown } from 'react-bootstrap';

interface IProps {
  className: string;
  markers: IMarker[] | undefined;
  mapContainerRef: React.Ref<HTMLDivElement>;
  isLoaded: boolean;
  center: google.maps.LatLngLiteral;
  zoom: number;
  handleOutsideClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  onClickMarker: ((e: google.maps.MapMouseEvent) => void) | undefined;
  onChangeState: (state: IMarker) => void;
  currentState: IMarker | undefined;
}

const MapViewNoMemo: React.FC<IProps> = (props) => {
  return (
    <MapWrapper className={`map-component ${props.className} container padding-0`} ref={props.mapContainerRef}>
      <div className='filter-container'>
        <h1 className="txt-black container text-align-center">Portos do Brasil</h1>

        <Dropdown>
          <Dropdown.Toggle variant="" id="dropdown-basic" className='select-state-field'>
            {props.currentState?.label || "Estados do Brasil"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {brazilStates.map(state => (
              <Dropdown.Item
                active={props.currentState && props.currentState.label === state.label}
                key={state.label}
                onClick={() => props.onChangeState(state)}>{state.label}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {
        props.isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '10px' }}
            center={props.center}
            zoom={props.zoom}
          >
            {
              props.markers?.map((marker) => {
                return <Marker
                  key={marker.label}
                  marker={marker}
                  onClickMarker={props.onClickMarker}
                />
              })
            }
          </GoogleMap>
        )}
    </MapWrapper >
  )
}

const MapView = React.memo(MapViewNoMemo);
export default MapView;

import React from 'react';
import AboutServiceCardView from './about-service-card.view';
import IAboutServiceCard from './about-service-card.props';

const AboutServiceCard: React.FC<IAboutServiceCard> = (props) => {
  const {
    imagePosition,
    description,
    title,
    imageUrl
  } = props;

  return (
    <AboutServiceCardView imagePosition={imagePosition} description={description} title={title} imageUrl={imageUrl} />
  );
};

export default AboutServiceCard;

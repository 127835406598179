import { IContainer } from "../../../Types";

interface IProps {
  container?: IContainer;
  productWeight: number;
  productVolume: number;
}

const productPercentualFormula = (props: IProps): number => {
  const {
    container,
    productVolume,
    productWeight,
  } = props;

  let volumePercentual =  productVolume / (container?.innerSize ?? Infinity);
  let weightPercentual =  productWeight / (container?.weightCapacity ?? Infinity);

  return Math.max(volumePercentual, weightPercentual);
}

export default productPercentualFormula;
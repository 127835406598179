import React from "react";
import HeaderView from "./header.view";
import { IHeader } from "./";

const Header: React.FC<IHeader> = (props) => {
  const { onClickShowDrawer } = props;

  return (
    <HeaderView
      onClickShowDrawer={onClickShowDrawer} />
  );
};

export default Header;

import React from "react";
import logo from "../../Assets/Logo para fundo claro vertical.png";
import HeaderWrapper from "./header.style";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

interface IProps {
  onClickShowDrawer: () => void;
}

const HeaderView: React.FC<IProps> = (props) => {
  return (
    <HeaderWrapper className="header-component">
      <div className="content container">
        <div className="header-menu-icon">
          <MenuIcon onClick={props.onClickShowDrawer} />
        </div>
        <Link to="/" className="logo-container">
          <img src={logo} alt="Logotipo da Mid4u" />
        </Link>
        <div className="option-container">
          <nav className="option">
            <a className="option-link" href="/#cover" >Início</a>
          </nav>
          <nav className="option">
            <a className="option-link" href="/#about" >Serviços</a>
          </nav>
          <nav className="option">
            <a className="option-link" href="/#iconterms" >Incoterms</a>
          </nav>
          <nav className="option">
            <a className="option-link" href="/#containers" >Contêineres</a>
          </nav>
          <nav className="option">
            <a className="option-link" href="/#contact" >Contato</a>
          </nav>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default HeaderView;

import React from 'react';
import TaxCollectionWrapper from './tax-collection.style';
import { ITax } from '../../Types';
import { TaxUnit } from '../TaxUnit';

interface IProps {
  taxes: ITax[] | undefined;
}

const TaxCollectionViewNoMemo: React.FC<IProps> = (props) => (
  <TaxCollectionWrapper className='tax-collection-component'>
    {
      Boolean(props.taxes) && (
        <div className="tax-wrapper container">
          {
            props.taxes?.map((tax: ITax) =>
              <TaxUnit key={tax.name} tax={tax} />
            )
          }
        </div>
      )
    }
  </TaxCollectionWrapper>
);

const TaxCollectionView = React.memo(TaxCollectionViewNoMemo);
export default TaxCollectionView;

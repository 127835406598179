import { styled } from "styled-components";
import { HEADER_HEIGHT } from "../../Constants";

const CalculatorWrapper = styled.div`
  background-color: #EFEFEF;
  display: flex;
  height: 100%;
  flex-direction: column;

  .calculator-component {
    /* padding-top: ${HEADER_HEIGHT}px; */
  }
`

export default CalculatorWrapper;
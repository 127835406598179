import React from 'react';
import InputFieldWrapper from './input-field.style';
import TextField from '@mui/material/TextField';
import { BiHide, BiShow } from 'react-icons/bi';

interface IProps {
  icon?: JSX.Element;
  id: string;
  label: string;
  value: string;
  type: string;
  onBlur: () => void;
  onFocus: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  positionHelperClass: string;
  className?: string;
  error?: boolean;
  password: boolean;
  showText: boolean;
  toggleShowText: () => void;
}

const InputFieldViewNoMemo: React.FC<IProps> = (props) => (
  <InputFieldWrapper className={['input-field-component', props.positionHelperClass, props.className, props.error && "error"].join(' ')}>
    <div className="icon-wrapper">{props.icon}</div>
    <div className="label-field">
      <label htmlFor={props.id}>{props.label}</label>
      <TextField
        className="input-field-mui"
        id={props.id}
        type={props.type}
        value={props.value}
        variant="standard"
        InputProps={{ disableUnderline: true }}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={props.onChange}
        error={props.error}
      />
    </div>
    {props.password === true && 
      (props.showText
      ? <BiShow onClick={props.toggleShowText}/>
      : <BiHide onClick={props.toggleShowText}/>)
    }
  </InputFieldWrapper>
);

const InputFieldView = React.memo(InputFieldViewNoMemo);
export default InputFieldView;

import React from "react";
import ContainerCardWrapper from "./container-card.style";
import { FiPackage } from "react-icons/fi";
import { Link } from "react-router-dom";

interface IProps {
  id: string;
  image: string;
  name: string;
  innerSize: string;
  height: string;
  width: string;
  depth: string;
  weightCapacity: string;
  cubage: string;
}

const ContainerCardViewNoMemo: React.FC<IProps> = (props) => (
  <ContainerCardWrapper className="container-card-component bg-white">
    <div className="img-container">
      <img src={props.image} alt={props.name} draggable={false}/>
      <div className="container-name-overlay"></div>
      <div className="container-name container-spacing">
          <h3 className="">{props.name}</h3>
          <FiPackage />
      </div>
    </div>

    <div className="content">
      <div className="container-infos container-spacing">
        <div className="container-line txt-gray-1">
          <span>Volume interno</span>
          <span className="value">{props.innerSize} m³</span>
        </div>
        <div className="container-line txt-gray-1">
          <span>Altura</span>
          <span className="value">{props.height} m</span>
        </div>
        <div className="container-line txt-gray-1">
          <span>Largura</span>
          <span className="value">{props.width} m</span>
        </div>
        <div className="container-line txt-gray-1">
          <span>Profundidade</span>
          <span className="value">{props.depth} m</span>
        </div>
        <div className="container-line txt-gray-1">
          <span>Peso máximo</span>
          <span className="value">{props.weightCapacity} kg</span>
        </div>
        <div className="container-line txt-gray-1">
          <span>Cubagem máxima</span>
          <span className="value">{props.cubage} kg/m³</span>
        </div>
      </div>

      <div className="horizontal-spacer bg-gray-2"></div>

      <div className="cta-calculator txt-primary container-spacing">
        <Link to={`/container/${props.id}`}>Calculadora →</Link>
      </div>
    </div>
  </ContainerCardWrapper>
);

const ContainerCardView = React.memo(ContainerCardViewNoMemo);
export default ContainerCardView;

import { styled } from "styled-components";

const GuestsWrapper = styled.div`
  flex-grow: 1;
  padding-top: 2rem;
  max-height: 100vh;
  overflow-y: auto;

  .page-title {
    margin-bottom: 2rem;
  }


`

export default GuestsWrapper;
import { styled } from "styled-components";
import { BREAKPOINTS } from "../../Constants";

const FormUserWrapper = styled.div`
  flex-grow: 1;
  padding-top: 2rem;
  max-height: 100vh;
  overflow-y: auto;

  .page-title {
    margin-bottom: 2rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 1rem;

    form {
      row-gap: 2rem;

      @media (min-width: ${BREAKPOINTS.md}px) {
        max-width: 400px;
        width: 100%;
        margin: 0;
      }
    }
  }
`;

export default FormUserWrapper;

import { styled } from "styled-components";

const ContainerCardWrapper = styled.div`
  &.container-card-component{
    width: min(100%, 400px);
    min-width: 100%;
      
    .container-spacing {
      padding: 1rem 1rem;
    }

    h1, h2, h3, h4, h5 {
      margin: 0;
    }
      
    .img-container {
      max-height: 200px;
      width: 100%;
      height: 300px;
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;

      img {
        position: absolute;
        inset: 0;
        max-width: 100%;
        width: 100%;
      }

      .container-name-overlay {
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, rgba(6, 59, 99, 0) 45%, rgba(6, 59, 99, 0.8) 100%);
        mix-blend-mode: multiply;
      }
      
      .container-name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          height: 18px;
          width: 18px;
          stroke: white;
        }
      }
    }

    .content {

      .horizontal-spacer {
        height: 1px;
        width: 100%;
      }

      .container-infos {
        .container-line {
          display: grid;
          grid-template-columns: 2fr 1fr;
          cursor: pointer;

          &:not(:last-child) {
            margin-bottom: .5rem;
          }
          
          .value {
            text-align: right;
            transition: .3s;
          }

          &:hover {
            color: black;

            .value {
              font-weight: bold;
              transition: .3s;
            }
          }
        }
      }
        
      .cta-calculator {
        text-align: right;
        
        a {
          text-decoration: none;
        }        
      }
    }
  }
`;

export default ContainerCardWrapper;

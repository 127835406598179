import { styled, css } from "styled-components";

const AdminContainerCardWrapper = styled.div`${() => css`
  &.admin-container-card-component{
    display: flex;
    background-color: white;
    width: 100%;

    .img-container {
      height: 120px;
      width: 100px;
      overflow: hidden;
      display: flex;
      align-items: center;
      position: relative;

      img {
        position: absolute;
        inset: 0;
        max-height: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      .name {
        font-size: 1.2rem;
        margin-bottom: .5rem;
        display: flex;
        justify-content: space-between;

        .cta {
          display: flex;
          gap: .5rem;
        }
      }

      .info-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .5rem;
        width: 100%;
        --icon-size: 18px;

        .info {
          display: flex;
          gap: .5rem;
          
          .info-icon {
            max-width: var(--icon-size);
            max-height: var(--icon-size);
          }
        }
      }
    }
  }
`}`;

export default AdminContainerCardWrapper;

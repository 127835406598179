import { useCallback, useEffect } from "react"
import { userService } from "../Services"

const useValidateToken = () => {
  const validateToken = useCallback(async () => {
    await userService.getAll(1);
  }, [])

  useEffect(() => {
    validateToken();
  }, []);
}

export default useValidateToken;
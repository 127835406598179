import { styled, css } from "styled-components";

const UserCardWrapper = styled.div`
  ${() => css`
    &.user-card-component {
      background-color: white;
      display: flex;
      position: relative;
      min-height: 120px;
      width: 100%;

      .user-card__admin-icon {
        position: absolute;
        right: initial;
        inset: 0;
        
        .icon-wrapper {
          display: flex;
          align-items: center;
          transform: translateX(-5px);
          position: relative;
          height: 100%;

          svg {
            position: absolute;
            transform: rotate(-20deg);

            &.stroke {
              fill: #eb5406;
              stroke: white;
              stroke-width: 10px;
            }
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding: 2rem 1rem 2rem 2rem;

        .user-card__name {
          font-weight: bold;
          font-size: 1.2rem;
        }

        .user-card__email {
          opacity: 0.8;
        }
      }

      .cta {
        display: flex;
        opacity: .8;
        flex-direction: column;
        justify-content: center;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2rem 2rem 1rem;
      }
    }
  `}
`;

export default UserCardWrapper;

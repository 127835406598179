import React, { useCallback, useMemo, useState } from 'react';
import TextAreaFieldView from './text-area-field.view';
import ITextAreaField from './text-area-field.props';

const TextAreaField: React.FC<ITextAreaField> = (props) => {
  const {
    icon,
    id,
    label,
    type = 'text',
    className,
    error,
    onChange: onChangeProps,
  } = props;

  const [text, setText] = useState<string>("");

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);
  const positionHelperClass = useMemo<string>(() => {
    const classes = [];
    if (isFocused) classes.push('active');
    if (isFilled) classes.push('filled');
    return classes.join(' ');
  }, [isFocused, isFilled]);

  const onBlur = useCallback(() => setIsFocused(false), []);
  const onFocus = useCallback(() => setIsFocused(true), []);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChangeProps(value);
    setIsFilled(value !== "");
    setText(value ?? "");
  }, [onChangeProps, setIsFilled, setText]);

  return <TextAreaFieldView
    icon={icon} 
    id={id} 
    label={label}
    type={type}
    value={text}
    onBlur={onBlur}
    onFocus={onFocus}
    positionHelperClass={positionHelperClass}
    onChange={onChange}
    className={className}
    error={error}
  />;
};

export default TextAreaField;

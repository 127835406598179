import React from 'react';
import CustomFooterWrapper from './custom-footer.style';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';

interface IProps { }

const CustomFooterViewNoMemo: React.FC<IProps> = () => (
  <CustomFooterWrapper className='custom-footer-component bg-primary txt-white'>
    <div className="container content">
      <div className="left">
        <h1>Ainda ficou com alguma dúvida? Entre em contato com a gente!</h1>
      </div>
      <div className="right">
        <div className="info">
          <div className="address">
            CNPJ: 10.501.613/0001-66<br />
            R. João Alves Da Silva, 200<br />
            B. Jardim Oasis | Cajazeiras-PB<br />
            CEP: 58.900-000 | Brasil
          </div>

          <ul className="social-media">
            <li><a href="https://www.facebook.com/mid4ubrasil/"><Facebook /></a></li>
            <li><a href="https://www.instagram.com/midforyoubr/"><Instagram /></a></li>
            <li><a href="https://www.linkedin.com/company/mid4u/"><LinkedIn /></a></li>
          </ul>
        </div>
      </div>
    </div>
  </CustomFooterWrapper>
);

const CustomFooterView = React.memo(CustomFooterViewNoMemo);
export default CustomFooterView;


const PLANE_SVG = `
m 33.885822,1.7366442e-4
c -1.660081,0 -3.230813,0.61250318558 -4.410531,1.79232903558
L 23.957732,7.3100614 6.9745505,2.4514439
C 6.7908342,2.3987483 6.5280146,2.3284475 6.210176,2.3284475
c -0.7339427,0 -1.4356273,0.2934788 -1.9504759,0.8083034
L 1.6239292,5.7549586
C 1.0069349,6.371929 0.7386242,7.2742223 0.85076415,8.0832264 0.96304183,8.8936497 1.4863397,9.6831025 2.2389414,10.103985
L 14.372296,16.895503 9.7596896,21.499325 4.0136952,20.392297
c -0.2783288,-0.0521 -0.4643206,-0.03533 -0.5183935,-0.03533 -0.6882173,0 -1.4126269,0.253059 -1.9504699,0.790735
v 0.009
l -0.74680522,0.75559
c -0.59037101,0.597257 -0.88986199,1.468675 -0.77315907,2.310692 0.117667,0.848843 0.65266566,1.60914 1.38817129,2.020765
l 6.3697912,3.567083 3.567083,6.369791
c 0.472464,0.844346 1.386105,1.414531 2.389772,1.414531 0.734254,0 1.442148,-0.291024 1.95926,-0.808303
v -0.009
l 0.738015,-0.738008
c 0.631869,-0.631941 0.915383,-1.57687 0.746805,-2.460061
h 0.009
l -1.08947,-5.772348 4.586252,-4.577463 6.782728,12.11578
c 0.486896,0.870389 1.40404,1.414537 2.407341,1.414537 0.734524,0 1.442233,-0.291084 1.959261,-0.808303
l 2.618201,-2.618208
c 0.713973,-0.713697 0.959457,-1.759568 0.685301,-2.71485
L 30.28375,13.635744 35.792524,8.1181912
C 36.656146,7.2545633 37.188905,6.1497389 37.45306,4.9289003 37.717197,3.7080617 37.7257,2.1704563 36.574468,1.019164 35.84434,0.28901773 34.845679,1.0180671e-4 33.885977,0
Z

M 10.067193,21.560818
c 0.01497,0.0024 0.08856,0.0051 0.0615,0
v 0.009
z
`;

export default PLANE_SVG;
import React from 'react';
import TextAreaFieldWrapper from './text-area-field.style';
import { TextField } from '@mui/material';

interface IProps {
  icon: JSX.Element;
  id: string;
  label: string;
  type: string;
  value: string;
  onBlur: () => void;
  onFocus: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  positionHelperClass: string;
  className?: string;
  error?: boolean;
}

const TextAreaFieldViewNoMemo: React.FC<IProps> = (props) => (
  <TextAreaFieldWrapper className={['text-area-field-component', props.positionHelperClass, props.className].join(' ')}>
    <div className="icon">
      {props.icon}
    </div>
    <div className="label-field">
      <label htmlFor={props.id}>{props.label}</label>
      <TextField
        className="textarea-field-mui"
        id={props.id}
        type={props.type}
        variant="standard"
        value={props.value}
        rows={3}
        multiline
        maxRows={Infinity}
        InputProps={{ disableUnderline: true }}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onChange={props.onChange}
        error={props.error}
      />
    </div>
  </TextAreaFieldWrapper>
);

const TextAreaFieldView = React.memo(TextAreaFieldViewNoMemo);
export default TextAreaFieldView;

import React from 'react';
import MarkerWrapper from './marker.style';
import { Marker } from '@react-google-maps/api';
import { IMarker } from '../../../../Types';

interface IProps {
  marker: IMarker;
  iconPath: string;
  markerTitle: string;
  markerNameColor: string;
  onClickMarker: ((e: google.maps.MapMouseEvent) => void) | undefined;
}

const MarkerViewNoMemo: React.FC<IProps> = (props) => (
  <MarkerWrapper className='marker-component'>
    <Marker
      onClick={props.onClickMarker}
      position={{ lat: props.marker.lat, lng: props.marker.lng }}
      options={{
        label: {
          text: props.markerTitle,
          className: 'map-marker-label',
          color: 'black',
          fontSize: '10px',
          fontWeight: '600',
        },
        clickable: true
      }}
      icon={{
        path: props.iconPath,
        strokeColor: 'white',
        fillOpacity: 1,
        strokeWeight: 2,
        fillColor: props.markerNameColor,
        scale: 0.8,
      }}
    />
  </MarkerWrapper>
);

const MarkerView = React.memo(MarkerViewNoMemo);
export default MarkerView;

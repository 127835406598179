import React from 'react';
import PhoneFieldWrapper from './phone-field.style';
import { TextField } from '@mui/material';
import { IMaskInput } from "react-imask";

interface IProps {
  icon: JSX.Element;
  value: string;
  id: string;
  label: string;
  type: string;
  onBlur: () => void;
  onFocus: () => void;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  positionHelperClass: string;
  className?: string;
  error?: boolean;
}

interface IPhoneMask {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

const PhoneMask = React.forwardRef<HTMLElement, IPhoneMask>(
  function TextMaskCustom(props, ref) {
    const { onChange, value, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask="(00) #0000-0000"
        definitions={{ '#': /9?/ }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value }})}
        onChange={onChange}
        overwrite
        value={value}
      />
    );
  },
);

const PhoneFieldViewNoMemo: React.FC<IProps> = (props) => (
  <PhoneFieldWrapper className={['phone-field-component', props.positionHelperClass, props.className].join(' ')}>
  <div className="icon">
    {props.icon}
  </div>
  <div className="label-field">
    <label htmlFor={props.id}>{props.label}</label>
    <TextField
      className="phone-field-mui"
      id={props.id}
      type={props.type}
      variant='standard'
      onBlur={props.onBlur}
      onFocus={props.onFocus}
      onChange={props.onChange}
      value={props.value}
      error={props.error}
      InputProps={{
        inputComponent: PhoneMask as any,
        disableUnderline: true
      }}
    />
  </div>
  </PhoneFieldWrapper>
);

const PhoneFieldView = React.memo(PhoneFieldViewNoMemo);
export default PhoneFieldView;

import { IIncotermsData } from "../types";

const incotermsData: Record<string, IIncotermsData> = {
  EXW: {
    index: 0,
    name: "Ex Works - Saída de fábrica",
    acronym: "EXW",
    costStage: 0,
    riskStage: 0,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Aquaviário",
    moreDetails:
      "Ex Works (EXW) coloca a máxima responsabilidade sobre o comprador, incluindo todos os custos de transporte, riscos e liberação de exportação e importação. O vendedor é apenas responsável por disponibilizar as mercadorias em suas instalações ou outro local nomeado (fábrica, armazém, etc.). O comprador arca com todos os custos e riscos envolvidos em levar as mercadorias até o destino desejado.",
  },
  FCA: {
    index: 1,
    name: "Free Carrier - Livre no transportador",
    acronym: "FCA",
    costStage: 1,
    riskStage: 1,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Aquaviário",
    moreDetails:
      "Free Carrier (FCA) significa que o vendedor entrega as mercadorias, liberadas para exportação, ao transportador nomeado pelo comprador no local nomeado. O vendedor é responsável por carregar as mercadorias no veículo coletor do comprador. O comprador é responsável por todos os riscos e custos após este ponto, incluindo o descarregamento em suas instalações.",
  },
  FAS: {
    index: 2,
    name: "Free Alongside Ship - Livre ao lado do navio",
    acronym: "FAS",
    costStage: 2,
    riskStage: 2,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Aquaviário",
    moreDetails:
      "Free Alongside Ship (FAS) requer que o vendedor entregue as mercadorias ao lado do navio nomeado pelo comprador no porto nomeado de embarque. O vendedor deve liberar as mercadorias para exportação. O comprador arca com todos os custos e riscos de perda ou dano a partir desse momento.",
  },
  FOB: {
    index: 3,
    name: "Free On Board - Livre a bordo",
    acronym: "FOB",
    costStage: 2,
    riskStage: 2,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Aquaviário",
    moreDetails:
      "Free On Board (FOB) significa que o vendedor entrega as mercadorias, liberadas para exportação, a bordo do navio nomeado pelo comprador no porto nomeado de embarque. O vendedor é responsável por todos os custos e riscos até que as mercadorias sejam carregadas no navio.",
  },
  CFR: {
    index: 4,
    name: "Cost and Freight - Custo e frete",
    acronym: "CFR",
    costStage: 4,
    riskStage: 3,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Aquaviário",
    moreDetails:
      "Cost and Freight (CFR) requer que o vendedor entregue as mercadorias a bordo do navio ou a um porto de destino nomeado. O vendedor deve liberar as mercadorias para exportação, enquanto o comprador é responsável por todos os custos e riscos a partir desse ponto.",
  },
  CIF: {
    index: 5,
    name: "Cost, Insurance and Freight - Custo, seguro e frete",
    acronym: "CIF",
    costStage: 5,
    riskStage: 5,
    safetyStage: 5,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Aquaviário",
    moreDetails:
      "Cost, Insurance and Freight (CIF) coloca responsabilidade adicional sobre o vendedor para obter seguro contra o risco de perda ou dano ao comprador durante o transporte. O vendedor entrega as mercadorias a bordo do navio ou a um porto de destino nomeado, com o comprador arrependendo todos os custos e riscos a partir desse momento.",
  },
  CPT: {
    index: 6,
    name: "Carriage Paid To - Transporte pago até",
    acronym: "CPT",
    costStage: 5,
    riskStage: 5,
    safetyStage: 5,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Qualquer modal",
    moreDetails:
      "Carriage Paid To (CPT) significa que o vendedor entrega as mercadorias a um transportador ou outra pessoa nomeada pelo vendedor em um local acordado, e o vendedor deve contratar e pagar os custos de transporte necessários para levar as mercadorias ao local nomeado de destino.",
  },
  CIP: {
    index: 7,
    name: "Carriage and Insurance Paid To - Transporte e seguro pagos até",
    acronym: "CIP",
    costStage: 7,
    riskStage: 7,
    safetyStage: 8,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Qualquer modal",
    moreDetails:
      "Carriage and Insurance Paid To (CIP) requer que o vendedor entregue as mercadorias a um transportador ou outra pessoa nomeada pelo vendedor em um local acordado, e o vendedor deve contratar e pagar os custos de transporte necessários para levar as mercadorias ao local nomeado de destino. Além disso, o vendedor deve obter seguro contra o risco de perda ou dano ao comprador durante o transporte.",
  },
  DAP: {
    index: 8,
    name: "Delivered at Place - Entregue no local",
    acronym: "DAP",
    costStage: 7,
    riskStage: 7,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Qualquer modal",
    moreDetails:
      "Delivered at Place (DAP) significa que o vendedor entrega as mercadorias, liberadas para importação, ao comprador no local nomeado de destino. O vendedor arca com todos os riscos e custos associados ao transporte das mercadorias até esse local, incluindo taxas, impostos e outras cobranças.",
  },
  DPU: {
    index: 9,
    name: "Delivered at Place Unloaded - Entregue no local desembarcado",
    acronym: "DPU",
    costStage: 7,
    riskStage: 7,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Qualquer modal",
    moreDetails:
      "Delivered at Place Unloaded (DPU) significa que o vendedor entrega as mercadorias, liberadas para importação, ao comprador no local nomeado de destino. O vendedor é responsável por todos os custos e riscos associados ao transporte das mercadorias até esse local, incluindo taxas, impostos e outras cobranças.",
  },
  DDP: {
    index: 10,
    name: "Delivered Duty Paid - Entregue com direitos pagos",
    acronym: "DDP",
    costStage: 7,
    riskStage: 7,
    safetyStage: 0,
    freightDetails: "Responsabilidade da fábrica",
    riskDetails: "Qualquer modal",
    moreDetails:
      "Delivered Duty Paid (DDP) significa que o vendedor é responsável por todas as despesas e riscos associados à entrega das mercadorias ao local nomeado de destino, incluindo o pagamento de impostos e taxas de importação. O vendedor é obrigado a fazer a entrega das mercadorias no local nomeado de destino",
  },
};

export default incotermsData;

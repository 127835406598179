import { IIncotermsStages } from "../types";
import icon00 from "../../../Assets/incoterms/00.svg";
import icon01 from "../../../Assets/incoterms/01.svg";
import icon02 from "../../../Assets/incoterms/02.svg";
import icon03 from "../../../Assets/incoterms/03.svg";
import icon04 from "../../../Assets/incoterms/04.svg";
import icon05 from "../../../Assets/incoterms/05.svg";
import icon06 from "../../../Assets/incoterms/06.svg";
import icon07 from "../../../Assets/incoterms/07.svg";
import icon08 from "../../../Assets/incoterms/08.svg";

const incotermsStages: IIncotermsStages[] = [
  {
    index: 0,
    icon: icon00,
    name: 'Fábrica / Fornecedor',
  },
  {
    index: 1,
    icon: icon01,
    name: 'Transporte',
  },
  {
    index: 2,
    icon: icon02,
    name: 'No porto',
  },
  {
    index: 3,
    icon: icon03,
    name: 'A bordo',
  },
  {
    index: 4,
    icon: icon04,
    name: '',
  },
  {
    index: 5,
    icon: icon05,
    name: 'Na chegada',
  },
  {
    index: 6,
    icon: icon06,
    name: 'No destino',
  },
  {
    index: 7,
    icon: icon07,
    name: 'Transporte',
  },
  {
    index: 8,
    icon: icon08,
    name: 'Cliente',
  },
];

export default incotermsStages;
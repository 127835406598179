import React, { useMemo, useState } from "react";
import { InputField, PhoneField, TextAreaField } from "../Components";
import {
  BiUserCircle,
  BiMailSend,
  BiBuildingHouse,
  BiBriefcaseAlt,
  BiMessage,
  BiMapPin,
  BiPhone,
} from "react-icons/bi";
import { IGuestBody } from "../Services/Types";

const useRenderGuestFormFields = (): {
  renderGuestFormFields: React.ReactNode
  guestBody: IGuestBody
} => {

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [lineOfBusiness, setLineOfBusiness] = useState<string>('');
  const [locality, setLocality] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const renderGuestFormFields = useMemo<React.ReactNode>(() => {
    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <InputField
            id="guest-name"
            label="Nome completo"
            icon={<BiUserCircle />}
            onChange={setName}
          />
        </div>
        <div className="col-12 col-md-6">
          <InputField
            id="guest-email"
            label="E-mail"
            icon={<BiMailSend />}
            onChange={setEmail}
          />
        </div>
        <div className="col-12 col-md-6">
          <PhoneField
            id="guest-phone"
            label="Telefone"
            icon={<BiPhone />}
            onChange={setPhone}
          />
        </div>
        <div className="col-12 col-md-6">
          <InputField
            id="guest-company"
            label="Nome da empresa"
            icon={<BiBuildingHouse />}
            onChange={setCompanyName}
          />
        </div>
        <div className="col-12 col-md-6">
          <InputField
            id="guest-work"
            label="Ramo da atividade"
            icon={<BiBriefcaseAlt />}
            onChange={setLineOfBusiness}
          />
        </div>
        <div className="col-12 col-md-6">
          <InputField
            id="guest-place"
            label="Local"
            icon={<BiMapPin />}
            onChange={setLocality}
          />
        </div>
        <div className="col-12">
          <TextAreaField
            id="guest-message"
            label="Mensagem"
            icon={<BiMessage />}
            onChange={setMessage}
          />
        </div>
      </div>
    );
  }, []);

  return {
    renderGuestFormFields,
    guestBody: {
      name,
      email,
      phone,
      companyName,
      lineOfBusiness,
      locality,
      message,
    }
  };
};

export { useRenderGuestFormFields };

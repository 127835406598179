import { styled, css } from "styled-components";
import { BREAKPOINTS, DASHBOARD_HEADER_WIDTH } from "../../Constants";

const DashboardHeaderWrapper = styled.div`${() => css`
  &.dashboard-header-component{
    
  }
`}`;

const DesktopHeader = styled.div`${() => css`
  @media (min-width: ${BREAKPOINTS.md}px) {
    display: flex;
  }

  display: none;
  flex-direction: column;
  max-width: ${DASHBOARD_HEADER_WIDTH}px;
  width: ${DASHBOARD_HEADER_WIDTH}px;
  padding: 2rem 1rem;
  height: 100%;
  border-radius: 0 .5rem .5rem 0;
  gap: 1rem;

  .logo-container {
    width: 100%;
    display: flex;
    
    img.mid4u-logo {
      max-width: 100%;
      max-height: 60px;
      margin-inline: auto;
    }
  }

  nav {
    > * {
      padding: 1rem;
      display: flex;
      align-items: center;
      gap: .5rem;
      text-decoration: none;
      border-radius: .5rem;
      color: white;
      transition: .3s;

      &.active {
        transition: .3s;
        background: var(--dark-primary)
      }
    }
  }
`}`;

const MobileHeader = styled.div`${() => css`
  @media (min-width: ${BREAKPOINTS.md}px) {
      display: none;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 0 .75rem;
    --icon-size: 24px;

    nav {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary);
      border-radius: 20rem;
      height: fit-content;
      
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .75rem 0;
        border: 4px solid transparent;
        transition: .3s;
        
        &.active {
          border-top-color: var(--white);
          border-bottom-color: var(--white);
          transition: .3s;
        }

        .nav-icon {
          height: var(--icon-size);
          width: var(--icon-size);
          min-width: var(--icon-size);

          svg {
            height: var(--icon-size);
            width: var(--icon-size);
          }
        }

        .nav-name {
          display: none;
        }
      }
    }
  }
`}`;

export {
  DashboardHeaderWrapper,
  DesktopHeader,
  MobileHeader,
};

import React from 'react';
import IncotermsDropdownView from './incoterms-dropdown.view';
import IIncotermsDropdown from './incoterms-dropdown.props';

const IncotermsDropdown: React.FC<IIncotermsDropdown> = (props) => {
  const {
    className = '',
    incotermsOptions,
    listInteractionRefs,
    floatingStyles,
    onChangeIncoterm,
    getFloatingProps,
  } = props;

  return <IncotermsDropdownView
    className={className}
    incotermsOptions={incotermsOptions}
    listInteractionRefs={listInteractionRefs}
    floatingStyles={floatingStyles}
    onChangeIncoterm={onChangeIncoterm}
    getFloatingProps={getFloatingProps}
  />;
};

export default IncotermsDropdown;

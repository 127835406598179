//import axios from "axios";

import { guests } from "../Mocks"
import { IGuest } from "../Types"
import Service from "./Service";
import { IGuestBody } from "./Types";
import IPagination from "./Types/IPagination";

const controller = 'Visitor'

const guestService = {
  getAll: async (page: number = 1, rowsPerPage: number = 5): Promise<IPagination<IGuest>> => {
    return await Service.get(`Visitor?PageNumber=${page}&PageSize=${rowsPerPage}`) as IPagination<IGuest>;
    // return Promise.resolve(guests);
  },

  get: (id: string): Promise<IGuest | undefined> => {
    return Promise.resolve(guests.find(guest => guest.id === id));
  },

  post: (body: IGuestBody): Promise<IGuest> => {
    return Service.post(controller, body);
  }
}

export default guestService
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MapView from './map.view';
import IMap from './map.props';
import { AIRPORTS, DEFAULT_MAP_CENTER } from './constants';
import { ports } from '../../Mocks';
import { useJsApiLoader } from '@react-google-maps/api';
import { IMarker } from '../../Types'

const Map: React.FC<IMap> = (props) => {
  const {
    className = '',
  } = props;

  const mapContainerRef = useRef(null);
  const [center, setCenter] = useState(DEFAULT_MAP_CENTER);
  const [zoom, setZoom] = useState(5);
  const [markers, setMarkers] = useState<IMarker[]>();
  const [currentState, setCurrentState] = useState<IMarker>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDKhBBIdo-9CFMlcObXeRstXDO8712dPqQ",
    version: "weekly",
  });

  const onChangeState = useCallback((state: IMarker) => {
    setCurrentState(state);
    setZoom(5);

    setTimeout(() => {
      const targetZoom = 10;
      const currentZoom = 5;
      const zoomStep = (targetZoom - currentZoom) / 10;

      setCenter({ lat: state.lat, lng: state.lng });

      let currentZoomLevel = currentZoom;
      const zoomInterval = setInterval(() => {
        currentZoomLevel += zoomStep;
        if (currentZoomLevel >= targetZoom) {
          clearInterval(zoomInterval);
          currentZoomLevel = targetZoom;
        }
        setZoom(currentZoomLevel);
      }, 50);
    }, 500);
  }, []);

  const onClickMarker = useCallback((e: google.maps.MapMouseEvent) => {
    setZoom(5);
    const center = {
      lat: e.latLng?.lat() || -3.745,
      lng: e.latLng?.lng() || -38.523
    }

    setTimeout(() => {
      const targetZoom = 10;
      const currentZoom = 5;
      const zoomStep = (targetZoom - currentZoom) / 10;

      setCenter(center);

      let currentZoomLevel = currentZoom;
      const zoomInterval = setInterval(() => {
        currentZoomLevel += zoomStep;
        if (currentZoomLevel >= targetZoom) {
          clearInterval(zoomInterval);
          currentZoomLevel = targetZoom;
        }
        setZoom(currentZoomLevel);
      }, 50);
    }, 500);
  }, []);

  const onClickOutMap = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
  }, []);

  const initializeMarkers = useCallback(() => {
    const formattedPorts: IMarker[] = [];
    ports.forEach(port => {
      formattedPorts.push({
        label: port.port,
        lat: port.latitude,
        lng: port.longitude,
        type: 'port',
      });
    });

    const markers = [...formattedPorts, ...AIRPORTS]

    setMarkers(markers)
  }, []);

  useEffect(initializeMarkers, [initializeMarkers]);

  useEffect(() => {
    console.log(center)
  }, [center]);

  return (
    <MapView
      className={className}
      markers={markers}
      mapContainerRef={mapContainerRef}
      isLoaded={isLoaded}
      center={center}
      zoom={zoom}
      currentState={currentState}
      onClickMarker={onClickMarker}
      handleOutsideClick={onClickOutMap}
      onChangeState={onChangeState}
    />
  );
};

export default Map;

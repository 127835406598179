import React, { useMemo } from 'react';
import TaxUnitView from './tax-unit.view';
import ITaxUnit from './tax-unit.props';
import { formatCurrencyName, formatNumberToCurrencyString, formatFullName, formatCurrencySymbol } from '../../Library';

const TaxUnit: React.FC<ITaxUnit> = (props) => {
  const {
    tax
  } = props;

  const formattedValue = useMemo<string>(() => formatNumberToCurrencyString({ number: tax.value }), [tax]);
  const formattedSymbol = useMemo<string>(() => formatCurrencySymbol({  key: tax.name }), [tax]);
  const formattedName = useMemo<string>(() => formatCurrencyName({ name: tax.name }), [tax.name]);
  const formattedFullName = useMemo<string>(() => formatFullName({ key: tax.name }), [tax.name]);

  return <TaxUnitView
    name={formattedName}
    formattedValue={formattedValue}
    formattedFullName={formattedFullName}
    symbol={formattedSymbol}
    variation={tax.variation}
  />;
};

export default TaxUnit;

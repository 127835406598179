import React from "react";
import DrawerOptionsWrapper from "./drawer-options.style";
import logoBlue from "../../Assets/logo-blue.png";
import { BiDirections, BiFileBlank, BiGrid, BiHomeAlt2 } from "react-icons/bi";

interface IProps {}

const DrawerOptionsViewNoMemo: React.FC<IProps> = () => (
  <DrawerOptionsWrapper className="drawer-options-component">
    <ul className="drawer-list">
      <li className="drawer-option">
        <a href="/#cover">
          <BiHomeAlt2 />
          Início
        </a>
      </li>
      <li className="drawer-option">
        <a href="/#about">
          <BiFileBlank />
          Serviços
        </a>
      </li>
      <li className="drawer-option">
        <a href="/#iconterms">
          <BiDirections />
          Incoterms
        </a>
      </li>
      <li className="drawer-option">
        <a href="/#containers">
          <BiGrid />
          Containers
        </a>
      </li>
    </ul>

    <div className="logo-container">
      <img src={logoBlue} alt="" />
    </div>
  </DrawerOptionsWrapper>
);

const DrawerOptionsView = React.memo(DrawerOptionsViewNoMemo);
export default DrawerOptionsView;

import React, { useEffect, useState } from 'react';
import GuestsWrapper from "./style";
import { CustomTable } from '../../Components';
import { guestHeadCells } from '../../Components/Tables/Mock';
import { IGuest } from '../../Types';
import { guestService } from '../../Services';
import { useValidateToken } from '../../Hooks';

const Guests: React.FC = () => {
  const [guests, setGuests] = useState<IGuest[]>([]);

  useValidateToken();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    guestService
    .getAll(page, rowsPerPage)
    .then((paginationGuest) => {
      const {
        data: guests,
        totalRecords
      } = paginationGuest;
      setTotal(totalRecords);
      setGuests(guests ?? []);
    });
  }, [page, rowsPerPage])

  return <GuestsWrapper className="container">
    <h1 className="page-title">Visitantes</h1>
    <CustomTable
      total={total}
      headCells={guestHeadCells}
      rows={guests}
      onPageChange={setPage}
      onRowsPerPageChange={setRowsPerPage}
    />
  </GuestsWrapper>
}

export default Guests;
import { styled, css } from "styled-components";
import { BREAKPOINTS } from "../../Constants";

const CalculatorWrapper = styled.div`${() => css`
  &.calculator-component{
    padding: 2rem 0;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      color: var(--txt-gray-2);
      margin-bottom: 2rem;
    }

    .calculator-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
      
      @media (min-width: ${BREAKPOINTS.lg}px) {
        grid-template-columns: 2fr 1fr;
      }

      .calculator {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .calculator-form {
          background-color: white;
          padding: 2rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .fields {
            display: grid;
            grid-template-columns: 1fr;
            gap: 2rem;
            
            @media (min-width: ${BREAKPOINTS.md}px) {
              grid-template-columns: minmax(0, 2fr) repeat(2, minmax(0, 1fr));
            }
          }
        }

        .calculator-products {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }
  }
`}`;

export default CalculatorWrapper;

import styled, { css } from "styled-components";

const AboutServicesWrapper = styled.div`${() => css`
  &.about-services-component{
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`}`;

export default AboutServicesWrapper;

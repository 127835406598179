import { Outlet } from "react-router-dom";
import { DashboardHeader } from "../../Components";
import DashboardWrapper from "./style";

const Dashboard: React.FC = () => {
  return <DashboardWrapper className="bg-accent">
    <DashboardHeader />
    <Outlet/>
  </DashboardWrapper>
}

export default Dashboard;
import { styled } from "styled-components";
import { BREAKPOINTS } from "../../Constants";

const DashboardWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${BREAKPOINTS.md}px) {
    flex-direction: row;
  }
`

export default DashboardWrapper;
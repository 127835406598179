import { styled, css } from "styled-components";
import { BREAKPOINTS, VISUALIZER_HEIGHT } from "../../../../Constants";

const VisualizerWrapper = styled.div`${() => css`
  &.visualizer-component{
    background-color: #DFDFDF;
    min-height: ${VISUALIZER_HEIGHT}px;
    max-height: ${VISUALIZER_HEIGHT}px;
    height: ${VISUALIZER_HEIGHT}px;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 0px solid transparent;
    transition: .5s border;

    &.overflow {
      border: 5px solid var(--error);
      transition: .5s border;
    }

    .container-details {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 99;
      opacity: .8;
    }

    .container-details,
    .water .product-details {
      padding: 1rem;
    }
    
    .title-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: .5rem;
      font-size: 1.2rem;

      .bold-title {
        font-weight: bold;
      }
      
      .splitted-values {
        display: flex;
        gap: .5rem;
        justify-content: center;
        width: 100%;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .water {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      position: relative;

      .wave-animation {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        .under-water {
          background: #bfabab;
          height: 100%;
          background-color: #0E9DCB;
          transition: 1s max-height ease-in-out;
        }
      }
      
      .product-details {
        position: absolute;
        inset: 0;
        top: initial;
      }
    }
  }

  .waves {
    position:relative;
    width: 100%;
    height:15px;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:10px;
    max-height:20px;
  }

  .content {
    position:relative;
    height:150px;
    text-align:center;
    background-color: white;
  }

  /* Animation */

  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
    transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: ${BREAKPOINTS.md}px) {
    .waves {
      height:40px;
      min-height:40px;
    }
    .content {
      height:30vh;
    }
    h1 {
      font-size:24px;
    }
  }
`}`;

export default VisualizerWrapper;
